
import {
  IonButton, IonContent, IonLabel, IonPage,
  IonSearchbar, IonSegment, IonSegmentButton
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { gamesList, modalGalaxyWards, modalSignUp, tagsList, tokenValue } from '../state.js';
import { ResponsiveImage, ResponsiveImageSize } from 'react-responsive-image';
import Header from '../components/Header';
import CardGame from '../components/CardGame';
import Footer from '../components/Footer';
import searchIcon from '../images/icons-input/search.svg';
import galaxyDailySmallImg from '../images/galaxy-img/daily-small.png';
import galaxyDailyImg from '../images/galaxy-img/daily-img.png';
import LiveFeed from '../components/LiveFeed.js';


const Home = (props) => {
  /*eslint-disable*/
  const [, setSignUpOpen] = useAtom(modalSignUp);
  const [, setGalaxyWardsOpen] = useAtom(modalGalaxyWards);
  const [token,] = useAtom(tokenValue);
  const [gamesDefault,] = useAtom(gamesList);
  const [games, setGames] = useState([]);
  const [tags,] = useAtom(tagsList);
  const [gamesRendered, setGamesRendered] = useState(12);
  const [gamesСategories, setGamesСategories] = useState('all');
  const [search, setSearch] = useState('');


  // filter games
  useEffect(() => {
    const mainPageFilter = (filter) => {
      if (filter !== 'all') {
        const tempArr = gamesDefault.filter(game => {
          if (game.tags[0].tag.includes(filter)) {
            return game;
          }
        });
        setGames(tempArr);
      } else {
        setGames(gamesDefault);
      }
    }
    mainPageFilter(gamesСategories)
  }, [gamesDefault, gamesСategories]);


  // search games
  useEffect(() => {
    const searchGames = (searchValue) => {
      const tempArr = gamesDefault.filter(game => {
        return game.name.toUpperCase().includes(searchValue.toUpperCase());
      });
      setGames(tempArr);
    }
    searchGames(search);
  }, [search]);



  return (
    <IonPage>
      <IonContent className="home">
        <Header logoutHandler={props.logoutHandler} />
        <div className="banner-container">
          <div className="banner-content">
            <div className="banner-content-text">
              <span className="text-transparent">Experience</span> perfection <span className="text-transparent">with</span> <span className="text-purple">Galaxy</span>
            </div>
            {!token && <div className="banner-content-btn-container btn-container">
              <IonButton
                className="banner-auth-signup-btn"
                onClick={() => setSignUpOpen(true)}
              >Sign Up
              </IonButton>
            </div>}
          </div>
        </div>
        <main className="main-body home-body">
          <div className="body-content">

            {token &&
              <div className="galaxy-wards-daily-wrapper">
                <div className="galaxy-wards-daily-recback flex">
                  <div className="galaxy-img-container flex">
                    <ResponsiveImage>
                      <ResponsiveImageSize
                        default
                        minWidth={0}
                        path={galaxyDailySmallImg}
                      />
                      <ResponsiveImageSize
                        minWidth={3400}
                        path={galaxyDailyImg}
                      />
                    </ResponsiveImage>
                    <p>Galaxy Wards</p>
                  </div>

                  <div className="galaxy-level-up">
                    <div className="galaxy-level-up-title">Daily Recback</div>
                    <div className="galaxy-level-up-indicator">
                      <div className="galaxy-level-up-indicator-line"></div>
                      <div className="galaxy-level-up-indicator-percent">10%</div>
                    </div>
                    <div className="galaxy-nexet-level-info">$1 000 for next level</div>
                  </div>

                  <div className="galaxy-btn-container flex">
                    <div className="btn-container">
                      <IonButton
                        onClick={() => setGalaxyWardsOpen(true)}
                        className="btn-orange"
                        type="submit"
                      >Open galaxywards
                    </IonButton>
                    </div>
                  </div>

                </div>
              </div>
            }

            <div className="home-games-container">
              <div className="home-games-top">
                <IonSegment
                  scrollable
                  className="home-games-menu"
                  mode="ios"
                  value={gamesСategories}
                  onIonChange={e => setGamesСategories(e.detail.value)}
                >
                  <IonSegmentButton value="all">
                    <IonLabel>All</IonLabel>
                  </IonSegmentButton>
                  {
                    tags.map((el, i) => {
                      return (
                        <IonSegmentButton key={i} value={el.tag}>
                          <IonLabel>{el.tag.replace(/games/g, '')}</IonLabel>
                        </IonSegmentButton>
                      )
                    })
                  }
                </IonSegment>

                <IonSearchbar
                  className="home-games-search"
                  mode="ios"
                  value={search}
                  placeholder="SEARCH"
                  onIonChange={e => setSearch(e.detail.value)}
                  searchIcon={searchIcon}
                  type="text"
                ></IonSearchbar>
              </div>
              <div className="home-games-list">
                {games.map((game, i) => {
                  if (i < gamesRendered) {
                    return (
                      <CardGame
                        key={i}
                        game={game}
                        showGame={props.showGame}
                        showDemoGame={props.showDemoGame}
                      />
                    )
                  }
                })
                }
              </div>
              {gamesRendered < games.length &&
                <div className="home-more-games-btn-container btn-container">
                  <IonButton
                    className="btn-orange"
                    onClick={() => setGamesRendered(gamesRendered + 12)}
                  >More Games
                  </IonButton>
                </div>
              }
            </div>

            <LiveFeed />
          </div>

        </main>
        <Footer />

        {/* <IonSegment
          className="home-games-menu tablet"
          mode="ios"
          value={gamesСategories}
          onIonChange={e => setGamesСategories(e.detail.value)}
        >
          <IonSegmentButton value="slots">
            <IonLabel>Slots</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="crash">
            <IonLabel>Crash</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="gameShows">
            <IonLabel>Game Shows</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="blackjack">
            <IonLabel>Blackjack</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="baccart">
            <IonLabel>Baccarat</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <IonSegment
          className="home-games-menu tablet bottom"
          mode="ios"
          value={gamesСategories}
          onIonChange={e => setGamesСategories(e.detail.value)}
        >
          <IonSegmentButton value="roulette">
            <IonLabel>Roulette</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="mins">
            <IonLabel>Mines</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="dise">
            <IonLabel>Dice</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="towers">
            <IonLabel>Towers</IonLabel>
          </IonSegmentButton>
        </IonSegment> */}

      </IonContent>

    </IonPage>
  )
}

export default Home;
