import React, { useEffect, useState } from 'react';
import {
  IonButton, IonModal, IonCheckbox, IonIcon, IonInput,
  IonSelect, IonSelectOption, IonItem, IonLabel, IonDatetime
} from '@ionic/react';
import closeModal from '../images/icons-other/close-modal.svg';
import userIcon from '../images/icons-header/account.svg';
import mailIcon from '../images/icons-input/mail.svg';
import calendarIcon from '../images/icons-input/calendar.svg';
import earthIcon from '../images/icons-input/earth.svg';
import cityIcon from '../images/icons-input/city.svg';
import localIcon from '../images/icons-input/local.svg';
import financeIcon from '../images/icons-input/finance.svg';
import passIconClose from '../images/icons-input/pass-invisible.svg';
import passIconOpen from '../images/icons-input/pass-visible.svg';
import validIcon from '../images/icons-input/validate.svg';
import { useAtom } from 'jotai';
import {
  currenciesList, countriesList, languagesList,
  errorToastTextValue, errorToastValue, modalSignUp,
  toastValue, toastTextValue
} from '../state.js';
import * as API from '../api/api.js';
import moment from 'moment';

const ModalSignUp = ({ checkField, checkRepasswordField }) => {
  const options = { cssClass: 'custom-select' }
  const [isOpen, setIsOpen] = useAtom(modalSignUp);
  const [step, setStep] = useState(1);
  const [, setErrorToast] = useAtom(errorToastValue);
  const [, setErrorToastText] = useAtom(errorToastTextValue);
  const [, setSuccessToast] = useAtom(toastValue);
  const [, setSuccessToastText] = useAtom(toastTextValue);
  const [button1Disabled, setButton1Disabled] = useState(true);
  const [button2Disabled, setButton2Disabled] = useState(true);
  const [button3Disabled, setButton3Disabled] = useState(true);
  const [buttonFinalDisabled, setButtonFinalDisabled] = useState(true);

  // Step 1
  const [userName, setUserName] = useState('');
  const [userNameSuccess, setUserNameSuccess] = useState();
  const [userNameErrorText, setUserNameErrorText] = useState('');
  const [email, setEmail] = useState('');
  const [emailSuccess, setEmailSuccess] = useState();
  const [emailErrorText, setEmailErrorText] = useState('');
  const [password, setPassword] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState();
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [repassword, setRepassword] = useState('');
  const [repasswordSuccess, setRepasswordSuccess] = useState();
  const [repasswordErrorText, setRepasswordErrorText] = useState('');
  const [passVisible, setPassVisible] = useState(false);
  const [checked0, setChecked0] = useState(false);
  const [checked0Success, setChecked0Success] = useState();

  // Step 2
  const [firstName, setFirstName] = useState('');
  const [firstNameSuccess, setFirstNameSuccess] = useState();
  const [firstNameErrorText, setFirstNameErrorText] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameSuccess, setLastNameSuccess] = useState();
  const [lastNameErrorText, setLastNameErrorText] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [birthDateSuccess, setBirthDateSuccess] = useState();
  const [birthDateErrorText, setBirthDateErrorText] = useState('');
  const [gender, setGender] = useState('');
  const [genderSuccess, setGenderSuccess] = useState();
  const [genderErrorText, setGenderErrorText] = useState('');
  const [country, setCountry] = useState('');
  const [countrySuccess, setCountrySuccess] = useState();
  const [countryErrorText, setCountryErrorText] = useState('');
  const countries = useAtom(countriesList)[0];
  // Step 3
  const [mobileCode, setMobileCode] = useState('');
  const [mobileCodeSuccess, setMobileCodeSuccess] = useState();
  const [, setMobileCodeErrorText] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileSuccess, setMobileSuccess] = useState();
  const [mobileErrorText, setMobileErrorText] = useState('');
  const [stateRegion, setStateRegion] = useState('');
  const [stateRegionSuccess, setStateRegionSuccess] = useState();
  const [stateRegionErrorText, setStateRegionErrorText] = useState('');
  const [city, setCity] = useState('');
  const [citySuccess, setCitySuccess] = useState();
  const [cityErrorText, setCityErrorText] = useState('');
  const [address, setAddress] = useState('');
  const [addressSuccess, setAddressSuccess] = useState();
  const [addressErrorText, setAddressErrorText] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [zipCodeSuccess, setZipCodeSuccess] = useState();
  const [zipCodeErrorText, setZipCodeErrorText] = useState('');
  // Step 4
  const [language, setLanguage] = useState('GB');
  const [languageSuccess, setLanguageSuccess] = useState(true);
  const [languageErrorText, setLanguageErrorText] = useState('');
  const languages = useAtom(languagesList)[0];
  const [currency, setCurrency] = useState('');
  const [currencySuccess, setCurrencySuccess] = useState();
  const [currencyErrorText, setCurrencyErrorText] = useState('');
  const currencies = useAtom(currenciesList)[0];
  const [daily, setDaily] = useState('');
  const [dailySuccess, setDailySuccess] = useState();
  const [dailyErrorText, setDailyErrorText] = useState('');
  const [weekly, setWeekly] = useState('');
  const [weeklySuccess, setWeeklySuccess] = useState();
  const [weeklyErrorText, setWeeklyErrorText] = useState('');
  const [monthly, setMonthly] = useState('');
  const [monthlySuccess, setMonthlySuccess] = useState();
  const [monthlyErrorText, setMonthlyErrorText] = useState('');
  const [checked1, setChecked1] = useState(false);
  const [checked1Success, setChecked1Success] = useState();
  const [checked2, setChecked2] = useState(false);
  const [checked2Success, setChecked2Success] = useState();
  const [checked3, setChecked3] = useState(false);



  // Check buttons

  useEffect(() => {
    const checkButton1Disabled = () => {
      if (userName && email && password && repassword) {
        setButton1Disabled(false);
      }
    }
    checkButton1Disabled();
  }, [userName, email, password, repassword])

  useEffect(() => {
    const checkButton2Disabled = () => {
      if (firstName && lastName && birthDate
        && gender && country) {
        setButton2Disabled(false);
      }
    }
    checkButton2Disabled();
  }, [firstName, lastName, birthDate, gender, country])

  useEffect(() => {
    const checkButton3Disabled = () => {
      if (mobileCode && mobile && stateRegion &&
        city && address && zipCode) {
        setButton3Disabled(false);
      }
    }
    checkButton3Disabled();
  }, [mobileCode, mobile, stateRegion, city, address, zipCode])

  useEffect(() => {
    const checkButtonFinalDisabled = () => {
      if (language && currency && daily &&
        weekly && monthly) {
        setButtonFinalDisabled(false);
      }
    }
    checkButtonFinalDisabled();
  }, [language, currency, daily, weekly, monthly])



  // Check on steps

  function firstStep() {
    if (userNameSuccess && emailSuccess && passwordSuccess &&
      repasswordSuccess && checked0Success) {
      setStep(2);
    } else {
      checkNick();
      checkEmail();
      checkPassword();
      checkRepassword();
      checkCheckbox0();
    }
  }

  function secondStep() {
    if (firstNameSuccess && lastNameSuccess && birthDateSuccess &&
      genderSuccess && countrySuccess) {
      setStep(3)
    } else {
      checkFirstName();
      checkLastName();
      checkBirthDate();
      checkGender();
      checkCountry();
    }
  }

  function thirdStep() {
    if (mobileCodeSuccess && mobileSuccess && stateRegionSuccess &&
      citySuccess && addressSuccess && zipCodeSuccess) {
      setStep(4)
    } else {
      checkMobileCode();
      checkMobile();
      checkStateRegion();
      checkCity();
      checkAddress();
      checkZipCode();
    }
  }

  function finalStep() {
    if (languageSuccess && currencySuccess && dailySuccess &&
      weeklySuccess && monthlySuccess && checked1Success &&
      checked2Success) {
      let data = {
        login: userName,
        password: password,
        email: email,
        title: gender,
        first_name: firstName,
        last_name: lastName,
        country: country,
        address_line: address,
        city: city,
        postcode: zipCode,
        state: stateRegion,
        phone: mobile,
        country_code: mobileCode,
        language: language,
        currency_id: currency,
        subscription: checked3,
        day_limit: daily,
        week_limit: weekly,
        month_limit: monthly,
        birth_date: moment(moment(birthDate).format('L')).unix()
      };
      API.register(data)
        .then(res => {
          setSuccessToastText('Registration has completed successfully');
          setSuccessToast(true);
          localStorage.setItem("token", res.data.data.token);
          setTimeout(() => window.location.reload(), 3000)
        }).catch(error => {
          setErrorToastText(error.response.data.error);
          setErrorToast(true);
        })
    } else {
      checkLanguage();
      checkCurrency();
      checkDaily();
      checkWeekly();
      checkMonthly();
      checkCheckbox1();
      checkCheckbox2();
    }
  }



  // Check fields & checkboxes

  function checkCheckbox(data, setCheckboxSuccess) {
    if (!data) {
      setCheckboxSuccess[0](false);
    } else {
      setCheckboxSuccess[0](true);
    }
  }

  function checkCheckbox0() {
    checkCheckbox(checked0, [setChecked0Success])
  }
  function checkCheckbox1() {
    checkCheckbox(checked1, [setChecked1Success]);
  }
  function checkCheckbox2() {
    checkCheckbox(checked2, [setChecked2Success])
  }

  function checkNick() {
    API.checkusername(userName)
      .then(() => {
        setUserNameSuccess(true);
      }).catch(error => {
        setUserNameSuccess(false);
        setUserNameErrorText(error.response.data.error);
      })
  }

  function checkEmail() {
    API.checkemail(email)
      .then(() => {
        setEmailSuccess(true);
      }).catch(error => {
        setEmailSuccess(false);
        setEmailErrorText(error.response.data.error);
      })
  }

  function checkPassword() {
    checkField(password, setPasswordSuccess, setPasswordErrorText);
  }
  function checkRepassword() {
    checkRepasswordField(
      password, repassword,
      setPasswordSuccess, setRepasswordSuccess,
      setPasswordErrorText, setRepasswordErrorText
    );
  }

  function checkFirstName() {
    checkField(firstName, setFirstNameSuccess, setFirstNameErrorText);
  }
  function checkLastName() {
    checkField(lastName, setLastNameSuccess, setLastNameErrorText);
  }
  function checkBirthDate() {
    checkField(birthDate, setBirthDateSuccess, setBirthDateErrorText);
  }
  function checkGender() {
    checkField(gender, setGenderSuccess, setGenderErrorText);
  }
  function checkCountry() {
    checkField(country, setCountrySuccess, setCountryErrorText);
  }
  function checkMobileCode() {
    checkField(mobileCode, setMobileCodeSuccess, setMobileCodeErrorText);
  }
  function checkMobile() {
    checkField(mobile, setMobileSuccess, setMobileErrorText);
  }
  function checkStateRegion() {
    checkField(stateRegion, setStateRegionSuccess, setStateRegionErrorText);
  }
  function checkCity() {
    checkField(city, setCitySuccess, setCityErrorText);
  }
  function checkAddress() {
    checkField(address, setAddressSuccess, setAddressErrorText);
  }
  function checkZipCode() {
    checkField(zipCode, setZipCodeSuccess, setZipCodeErrorText);
  }
  function checkLanguage() {
    checkField(language, setLanguageSuccess, setLanguageErrorText);
  }
  function checkCurrency() {
    checkField(currency, setCurrencySuccess, setCurrencyErrorText);
  }
  function checkDaily() {
    checkField(daily, setDailySuccess, setDailyErrorText);
  }
  function checkWeekly() {
    checkField(weekly, setWeeklySuccess, setWeeklyErrorText);
  }
  function checkMonthly() {
    checkField(monthly, setMonthlySuccess, setMonthlyErrorText);
  }



  return (
    <IonModal
      isOpen={isOpen}
      cssClass="modal"
      onDidDismiss={() => setIsOpen(false)}
      className="sign-modal"
    >
      <div className="modal-auth-container">

        <div className="modal-header">
          <div className="modal-title">Sign Up</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpen(false)
            }
          />
        </div>
        <div className="modal-login-content custom-scroll">
          <div className="modal-login-left">
            <div className="modal-login-head">Welcome back to Twin</div>
            <div className="modal-login-text">
              A gift for the restoration of the same mayzhe tviy! Memorize the form and cut off 200 free rounds in 10 days, a 200% bonus on your first deposit and a non-cash sports bet!</div>
          </div>
          <div className="modal-login-right">
            <div className="progress-bar">
              <ul className="flex">
                <li className="progress-active"></li>
                <li className={`${step >= 2 && 'progress-active'}`}></li>
                <li className={`${step >= 3 && 'progress-active'}`}></li>
                <li className={`${step >= 4 && 'progress-active'}`}></li>
              </ul>
            </div>



            {/*Step 1*/}
            {step === 1 && <>
              <form className="modal-login-form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (userNameSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkNick()}
                  >
                    <IonLabel position="floating">Username</IonLabel>
                    <IonInput
                      value={userName}
                      onIonChange={(e) => setUserName(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={userNameSuccess ? validIcon : userIcon}></IonIcon>
                  </IonItem>
                  {userNameSuccess === false && <div className="error-block">{userNameErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (emailSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkEmail()}
                  >
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={emailSuccess ? validIcon : mailIcon}></IonIcon>
                  </IonItem>
                  {emailSuccess === false && <div className="error-block">{emailErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (passwordSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkPassword()}
                  >
                    <IonLabel position="floating">Password</IonLabel>
                    <IonInput
                      value={password}
                      onIonChange={(e) => setPassword(e.detail.value)}
                      type={passVisible ? 'text' : 'password'}
                    ></IonInput>
                    <IonIcon
                      className="pass-icon"
                      slot="end"
                      icon={(passwordSuccess && validIcon)
                        || (passVisible ? passIconClose : passIconOpen)}
                      onClick={() => setPassVisible(!passVisible)}
                    ></IonIcon>
                  </IonItem>
                  {passwordSuccess === false && <div className="error-block">{passwordErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (repasswordSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkRepassword()}
                  >
                    <IonLabel position="floating">Repeat Password</IonLabel>
                    <IonInput
                      value={repassword}
                      onIonChange={(e) => setRepassword(e.detail.value)}
                      type={passVisible ? 'text' : 'password'}
                    ></IonInput>
                    <IonIcon
                      className="pass-icon"
                      slot="end"
                      icon={(repasswordSuccess && validIcon)
                        || (passVisible ? passIconClose : passIconOpen)}
                      onClick={() => setPassVisible(!passVisible)}
                    ></IonIcon>
                  </IonItem>
                  {repasswordSuccess === false && <div className="error-block">{repasswordErrorText}</div>}
                </div>

                <div className="btn-container">
                  <IonButton
                    onClick={() => firstStep()}
                    className="btn-orange"
                    type="submit"
                    disabled={button1Disabled}
                  >Continue
                  </IonButton>
                </div>

                <div className="modal-policy-container">
                  <div className="policy">
                    <IonItem
                      className={checked0Success === false ? 'error-checkbox' : ''}
                      lines="none"
                      onIonBlur={() => checkCheckbox0()}
                    >
                      <IonCheckbox
                        checked={checked0}
                        onIonChange={e => {
                          setChecked0(e.detail.checked);
                          setChecked0Success(e.detail.checked)
                        }}
                      />
                      <IonLabel>
                        This site is protected by reCAPTCHA and the Google <a href="/">Privacy Policy</a> and <a href="/">Terms of Service</a> apply. By accessing the site I attest that I am at least 18 years old and have read the <a href="/">Terms of Service</a>.
                      </IonLabel>
                    </IonItem>
                  </div>
                </div>
              </form>
            </>
            }



            {/*Step 2*/}
            {step === 2 && <>
              <form className="modal-login-form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (firstNameSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkFirstName()}
                  >
                    <IonLabel position="floating">First name</IonLabel>
                    <IonInput
                      value={firstName}
                      onIonChange={(e) => setFirstName(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={firstNameSuccess ? validIcon : userIcon}></IonIcon>
                  </IonItem>
                  {firstNameSuccess === false && <div className="error-block">{firstNameErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (lastNameSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkLastName()}
                  >
                    <IonLabel position="floating">Last name</IonLabel>
                    <IonInput
                      value={lastName}
                      onIonChange={(e) => setLastName(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={lastNameSuccess ? validIcon : userIcon}></IonIcon>
                  </IonItem>
                  {lastNameSuccess === false && <div className="error-block">{lastNameErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (birthDateSuccess === false ? ' error-input' : '')}
                    onIonChange={() => checkBirthDate()}
                  >
                    <IonLabel position="floating">Data of brith</IonLabel>
                    {/* <IonInput
                    value={birthDate}
                    onIonChange={(e) => setBirthDate(e.detail.value)}
                    type="text"></IonInput> */}
                    <IonDatetime
                      displayFormat="DD/MM/YYYY"
                      value={birthDate}
                      onIonChange={e => setBirthDate(e.detail.value)}
                    ></IonDatetime>
                    <IonIcon slot="end" icon={birthDateSuccess ? validIcon : calendarIcon}></IonIcon>
                  </IonItem>
                  {birthDateSuccess === false && <div className="error-block">{birthDateErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (genderSuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkGender()}
                  >
                    <IonSelect
                      value={gender}
                      interfaceOptions={options}
                      placeholder="Sex"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setGender(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Sex</IonSelectOption>
                      <IonSelectOption value={'mr'}>Mr</IonSelectOption>
                      <IonSelectOption value={'ms'}>Ms</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {genderSuccess === false && <div className="error-block">{genderErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (countrySuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkCountry()}
                  >
                    <IonSelect
                      value={country}
                      interfaceOptions={options}
                      placeholder="Country"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setCountry(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Country</IonSelectOption>
                      {countries.map(el => <IonSelectOption value={el.code} key={el.code}>{el.eng_name}</IonSelectOption>)}
                    </IonSelect>
                  </IonItem>
                  {countrySuccess === false && <div className="error-block">{countryErrorText}</div>}
                </div>

                <div className="login-btns-container flex">
                  <IonButton
                    onClick={() => setStep(1)}
                    className="btn-default"
                    type="submit"
                  >Back
                  </IonButton>

                  <div className="btn-container">
                    <IonButton
                      onClick={() => secondStep()}
                      className="btn-orange"
                      type="submit"
                      disabled={button2Disabled}
                    >Continue
                    </IonButton>
                  </div>

                </div>
              </form>
            </>
            }



            {/*Step 3*/}
            {step === 3 && <>
              <form className="modal-login-form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="number-container flex">
                  <IonItem
                    className={'ion-floating-style ccode' + (mobileCodeSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkMobileCode()}
                  >
                    <IonLabel position="floating">Code</IonLabel>
                    <IonInput
                      value={mobileCode}
                      onIonChange={(e) => setMobileCode(e.detail.value)}
                      type="text"
                    ></IonInput>
                  </IonItem>

                  <div className="item-input-wrapper">
                    <IonItem
                      className={'ion-floating-style phone-number' + (mobileSuccess === false ? ' error-input' : '')}
                      onIonBlur={() => checkMobile()}
                    >
                      <IonLabel position="floating">Phone number</IonLabel>
                      <IonInput
                        value={mobile}
                        onIonChange={(e) => setMobile(e.detail.value)}
                        type="text"
                      ></IonInput>
                      {mobileCodeSuccess && mobileSuccess && <IonIcon slot="end" icon={validIcon}></IonIcon>}
                    </IonItem>
                    {mobileSuccess === false && <div className="error-block">{mobileErrorText}</div>}
                  </div>
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (stateRegionSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkStateRegion()}
                  >
                    <IonLabel position="floating">State/Region</IonLabel>
                    <IonInput
                      value={stateRegion}
                      onIonChange={(e) => setStateRegion(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={stateRegionSuccess ? validIcon : earthIcon}></IonIcon>
                  </IonItem>
                  {stateRegionSuccess === false && <div className="error-block">{stateRegionErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (citySuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkCity()}
                  >
                    <IonLabel position="floating">City</IonLabel>
                    <IonInput
                      value={city}
                      onIonChange={(e) => setCity(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={citySuccess ? validIcon : cityIcon}></IonIcon>
                  </IonItem>
                  {citySuccess === false && <div className="error-block">{cityErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (addressSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkAddress()}
                  >
                    <IonLabel position="floating">Address</IonLabel>
                    <IonInput
                      value={address}
                      onIonChange={(e) => setAddress(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={addressSuccess ? validIcon : localIcon}></IonIcon>
                  </IonItem>
                  {addressSuccess === false && <div className="error-block">{addressErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (zipCodeSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkZipCode()}
                  >
                    <IonLabel position="floating">Zip Code</IonLabel>
                    <IonInput
                      value={zipCode}
                      onIonChange={(e) => setZipCode(e.detail.value)}
                      type="text"
                    ></IonInput>
                    <IonIcon slot="end" icon={zipCodeSuccess ? validIcon : financeIcon}></IonIcon>
                  </IonItem>
                  {zipCodeSuccess === false && <div className="error-block">{zipCodeErrorText}</div>}
                </div>

                <div className="login-btns-container flex">
                  <IonButton
                    onClick={() => setStep(2)}
                    className="btn-default"
                    type="submit"
                  >Back
                  </IonButton>

                  <div className="btn-container">
                    <IonButton
                      onClick={() => thirdStep()}
                      className="btn-orange"
                      type="submit"
                      disabled={button3Disabled}
                    >Continue
                    </IonButton>
                  </div>
                </div>
              </form>
            </>
            }



            {/*Step 4*/}
            {step === 4 && <>
              <form
                className="modal-login-form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (languageSuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkLanguage()}
                  >
                    <IonSelect
                      value={language}
                      interfaceOptions={options}
                      placeholder="Preferred Language"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setLanguage(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Preferred Language</IonSelectOption>
                      {languages.map(el => <IonSelectOption value={el.code} key={el.code}>{el.eng_name}</IonSelectOption>)}
                    </IonSelect>
                  </IonItem>
                  {languageSuccess === false && <div className="error-block">{languageErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (currencySuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkCurrency()}
                  >
                    <IonSelect
                      value={currency}
                      interfaceOptions={options}
                      placeholder="Currency"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setCurrency(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Currency</IonSelectOption>
                      {currencies.map((el, i) => <IonSelectOption key={i} value={el.id.toString()}>{el.full_name}</IonSelectOption>)}
                    </IonSelect>
                  </IonItem>
                  {currencySuccess === false && <div className="error-block">{currencyErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (dailySuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkDaily()}
                  >
                    <IonSelect
                      value={daily}
                      interfaceOptions={options}
                      placeholder="Daily Limit"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setDaily(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Daily Limit</IonSelectOption>
                      <IonSelectOption value={'20'}>20 ($)</IonSelectOption>
                      <IonSelectOption value={'40'}>40 ($)</IonSelectOption>
                      <IonSelectOption value={'60'}>60 ($)</IonSelectOption>
                      <IonSelectOption value={'80'}>80 ($)</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {dailySuccess === false && <div className="error-block">{dailyErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (weeklySuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkWeekly()}
                  >
                    <IonSelect
                      value={weekly}
                      interfaceOptions={options}
                      placeholder="Weekly Limit"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setWeekly(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Weekly Limit</IonSelectOption>
                      <IonSelectOption value={'50'}>50 ($)</IonSelectOption>
                      <IonSelectOption value={'100'}>100 ($)</IonSelectOption>
                      <IonSelectOption value={'200'}>200 ($)</IonSelectOption>
                      <IonSelectOption value={'300'}>300 ($)</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {weeklySuccess === false && <div className="error-block">{weeklyErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (monthlySuccess === false ? ' error-input' : '')}
                    lines="none"
                    onIonChange={() => checkMonthly()}
                  >
                    <IonSelect
                      value={monthly}
                      interfaceOptions={options}
                      placeholder="Monthly Limit"
                      interface={'popover'}
                      mode={'md'}
                      onIonChange={e => setMonthly(e.detail.value)}
                    >
                      <IonSelectOption disabled>Select Monthly Limit</IonSelectOption>
                      <IonSelectOption value={'200'}>200 ($)</IonSelectOption>
                      <IonSelectOption value={'400'}>400 ($)</IonSelectOption>
                      <IonSelectOption value={'600'}>600 ($)</IonSelectOption>
                      <IonSelectOption value={'800'}>800 ($)</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {monthlySuccess === false && <div className="error-block">{monthlyErrorText}</div>}
                </div>

                <div className="modal-policy-container">
                  <div className="policy">
                    <IonItem
                      className={(checked1Success === false ? 'error-checkbox' : '')}
                      lines="none"
                      onIonBlur={() => checkCheckbox1()}
                    >
                      <IonCheckbox
                        checked={checked1}
                        onIonChange={e => { setChecked1(e.detail.checked); setChecked1Success(e.detail.checked) }}
                      />
                      <IonLabel>I accept the <a href="/">Data Protection Policy</a>, <a href="/">Cookie Policy</a>, <a href="/">Disclaimer</a>, <a href="/">Welcome Bonus Terms and Conditions</a>.</IonLabel>
                    </IonItem>
                  </div>

                  <div className="policy">
                    <IonItem
                      className={(checked2Success === false ? 'error-checkbox' : '')}
                      lines="none"
                    >
                      <IonCheckbox
                        checked={checked2}
                        onIonChange={e => { setChecked2(e.detail.checked); setChecked2Success(e.detail.checked) }}
                      />
                      <IonLabel>I am of legal age to gamble and I accept the <a href="/">Terms and Conditions</a>, <a href="/">Responsible Gambling policy</a>, <a href="/">Contributions</a>, and <a href="/">KYC Policy</a>.
                  </IonLabel>
                    </IonItem>
                  </div>

                  <div className="policy">
                    <IonItem lines="none">
                      <IonCheckbox
                        checked={checked3}
                        onIonChange={e => setChecked3(e.detail.checked)}
                        onIonBlur={() => checkCheckbox2()}
                      />
                      <IonLabel>I want to recieve the latest promotions and exclusive offers from Bingo Bet, and the brands managed by Ridotto entertainment.</IonLabel>
                    </IonItem>
                  </div>
                </div>

                {/* <div className="btn-container">
                  <IonButton
                    onClick={() => finalStep()}
                    className="btn-orange"
                    type="submit"
                    disabled={buttonFinalDisabled}
                  >Continue
                  </IonButton>
                </div> */}
                <div className="login-btns-container flex">
                  <IonButton
                    onClick={() => setStep(3)}
                    className="btn-default"
                    type="submit"
                  >Back
                  </IonButton>

                  <div className="btn-container">
                    <IonButton
                      onClick={() => finalStep()}
                      className="btn-orange"
                      type="submit"
                      disabled={buttonFinalDisabled}
                    >Continue
                    </IonButton>
                  </div>

                </div>
              </form>
            </>
            }
          </div>
        </div>
      </div>
    </IonModal >
  )
}

export default ModalSignUp;
