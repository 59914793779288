import React from 'react';
import { Link } from 'react-router-dom';
import SingleCardWithdraw from './SingleCardWithdraw';
import warningIcon from '../../images/icons-oval/warning.svg';
import bitcoinIcon from '../../images/icons-oval/bitcoin-oval.svg';
import ethereumIcon from '../../images/icons-oval/ethereum-oval.svg';
import bitcoinSmallIcon from '../../images/icons-round/bitcoin-small.svg';
import ethereumSmallIcon from '../../images/icons-input/ethereum-small.svg';

const TabWithdraw = () => {
  return (
    <div className="modal-content-body">
      <div className="modal-body-item dark-blue-container flex">
        <div className="modal-body-item-description">
          <img className="icon" src={warningIcon} alt="warning icon" />
          <div className="modal-body-item-description-right">
            <div className="modal-body-item-heading">Confirmation Required</div>
            <div className="dark-blue-container-text">
              You must verify your email first before you can withdraw
            </div>
          </div>
        </div>
        <div className="modal-action-container">
          <Link to="/" className="modal-action open-action before">Open RooWards</Link>
        </div>
      </div>

      {[{ currency: 'Bitcoin', icon: bitcoinIcon, smallIcon: bitcoinSmallIcon }, { currency: 'Ethereum', icon: ethereumIcon, smallIcon: ethereumSmallIcon }]
        .map((item, i) => <SingleCardWithdraw item={item} i={i} key={i} />)
      }
    </div>
  )
}

export default TabWithdraw;
