import React from 'react';
import { IonButton, IonModal } from '@ionic/react';
import closeModal from '../images/icons-other/close-modal.svg';
import galaxyDailyImg from '../images/galaxy-img/daily-img.png';
import galaxyWeeklyImg from '../images/galaxy-img/weekly-img.png';
import galaxyMonthlyImg from '../images/galaxy-img/monthly-img.png';
import galaxyBigDailyImg from '../images/galaxy-img/daily-big.png';
import galaxyBigWeeklyImg from '../images/galaxy-img/weekly-big.png';
import galaxyBigMonthlyImg from '../images/galaxy-img/monthly-big.png';
// import { time } from 'ionicons/icons';
import { useAtom } from 'jotai';
import { modalGalaxyWards } from '../state';
import { ResponsiveImage, ResponsiveImageSize } from 'react-responsive-image';



const ModalGalaxyWards = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(modalGalaxyWards);

  return (
    <IonModal
      isOpen={isModalOpen}
      cssClass="modal"
      onDidDismiss={() => setIsModalOpen(false)}
    >
      <div className="modal-container">
        <div className="modal-galaxy-header">
          <div className="modal-title">GalaxyWards</div>
          <div className="modal-subtitle">Revard system</div>
          <div className="modal-description">RooWards is our way of giving back to loyal Roobetters like you. Level up just by playing, and unlock exclusive rewards like increased daily and monthly cash back on your wagers, permanent increases to lossback, and more!</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div className="modal-content modal-galaxy-content custom-scroll">
          <div className="modal-content-galaxy-body">

            <div className="modal-body-item flex">
              <div className="modal-body-left">
                <ResponsiveImage>
                  <ResponsiveImageSize
                    default
                    minWidth={0}
                    path={galaxyDailyImg}
                  />
                  <ResponsiveImageSize
                    minWidth={1921}
                    path={galaxyBigDailyImg}
                  />
                </ResponsiveImage>
              </div>
              <div className="modal-body-right">
                <div className="modal-galaxy-item-title">Daily Rakeback</div>
                <div className="modal-galaxy-item-description">
                  Level up to permanently increase the amount of rakeback you receive every day, based on your wagers yesterday.
                  </div>
                <div className="galaxy-level-up">
                  <div className="galaxy-level-up-indicator">
                    <div className="galaxy-level-up-indicator-line"></div>
                    <div className="galaxy-level-up-indicator-percent">10%</div>
                  </div>
                  <div className="galaxy-nexet-level-info">$1 000 for next level</div>
                </div>
                <IonButton className="btn-default btn-level" type="submit">You must reach level 1</IonButton>
                {/* Button Claim */}
                {/* <div className="modal-galaxy-btn-container flex">
                    <div className="btn-container">
                      <IonButton className="btn-orange" type="submit">claim</IonButton>
                    </div>
                  </div> */}
              </div>
            </div>

            <div className="modal-body-item flex">
              <div className="modal-body-left">
                <ResponsiveImage>
                  <ResponsiveImageSize
                    default
                    minWidth={0}
                    path={galaxyWeeklyImg}
                  />
                  <ResponsiveImageSize
                    minWidth={1921}
                    path={galaxyBigWeeklyImg}
                  />
                </ResponsiveImage>
              </div>
              <div className="modal-body-right">
                <div className="modal-galaxy-item-title">Weekly Cash Back</div>
                <div className="modal-galaxy-item-description">
                  Level up to permanently increase the amount of rakeback you receive every day, based on your wagers yesterday.
                  </div>
                <div className="galaxy-level-up">
                  <div className="galaxy-level-up-indicator">
                    <div className="galaxy-level-up-indicator-line"></div>
                    <div className="galaxy-level-up-indicator-percent">10%</div>
                  </div>
                  <div className="galaxy-nexet-level-info">$1 000 for next level</div>
                </div>
                <IonButton className="btn-default btn-level" type="submit">You must reach level 1</IonButton>
                {/* Button with icon */}
                {/* <IonButton className="btn-default btn-level" type="submit">
                  <IonIcon slot="start" icon={time} />
                    03:21:28:22
                  </IonButton> */}
              </div>
            </div>

            <div className="modal-body-item flex">
              <div className="modal-body-left">
                <ResponsiveImage>
                  <ResponsiveImageSize
                    default
                    minWidth={0}
                    path={galaxyMonthlyImg}
                  />
                  <ResponsiveImageSize
                    minWidth={1921}
                    path={galaxyBigMonthlyImg}
                  />
                </ResponsiveImage>
              </div>
              <div className="modal-body-right">
                <div className="modal-galaxy-item-title">Monthly Bonus</div>
                <div className="modal-galaxy-item-description">
                  Level up to permanently increase the amount of rakeback you receive every day, based on your wagers yesterday.
                  </div>
                <div className="galaxy-level-up">
                  <div className="galaxy-level-up-indicator">
                    <div className="galaxy-level-up-indicator-line"></div>
                    <div className="galaxy-level-up-indicator-percent">10%</div>
                  </div>
                  <div className="galaxy-nexet-level-info">$1 000 for next level</div>
                </div>
                <IonButton className="btn-default btn-level" type="submit">You must reach level 1</IonButton>
              </div>
            </div>

          </div>
        </div>
      </div>
    </IonModal>
  )
}

export default ModalGalaxyWards;
