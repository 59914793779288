import {
  IonButton, IonCheckbox, IonIcon, IonInput,
  IonItem, IonLabel, IonModal
} from '@ionic/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as API from '../api/api.js';
import closeModal from '../images/icons-other/close-modal.svg';
import userIcon from '../images/icons-header/account.svg';
import validIcon from '../images/icons-input/validate.svg';
import passIconClose from '../images/icons-input/pass-invisible.svg';
import passIconOpen from '../images/icons-input/pass-visible.svg';
import { useAtom } from 'jotai';
import {
  modalLogin, modalRestore, modalSignUp,
  tokenValue, requiredFieldErrorText
} from '../state';

const ModalLogin = () => {
  const [isOpen, setIsOpen] = useAtom(modalLogin);
  const [, setRestoreOpen] = useAtom(modalRestore);
  const [, setSignUpOpen] = useAtom(modalSignUp);
  const [, setToken] = useAtom(tokenValue);
  const [userName, setUserName] = useState('');
  const [userNameSuccess, setUserNameSuccess] = useState();
  const [password, setPassword] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [passVisible, setPassVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  const requiredFieldErrorValue = useAtom(requiredFieldErrorText)[0];

  function loginHandler() {
    // debugger
    if (userName && password) {
      API.login(userName, password)
        .then(res => {
          setToken(res.data.data.token);
          localStorage.setItem("token", res.data.data.token);
          // setIsOpen(false);
          document.location.reload();
        }).catch(() => {
          setErrorText('Incorrect login or password');
          setUserNameSuccess(false);
          setPasswordSuccess(false);
        })
    } else {
      if (userName && password === '') {
        setUserNameSuccess(true);
        setPasswordSuccess(false);
      } else if (password && userName === '') {
        setPasswordSuccess(true);
        setUserNameSuccess(false);
      } else {
        setUserNameSuccess(false);
        setPasswordSuccess(false);
      }
      setErrorText(requiredFieldErrorValue);
    }
  }

  return (
    <IonModal
      isOpen={isOpen}
      cssClass="modal"
      onDidDismiss={() => setIsOpen(false)}
      className="login-modal"
    >
      <div className="modal-auth-container">
        <div className="modal-header">
          <div className="modal-title">Log In</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className="modal-login-content custom-scroll">
          <div className="modal-login-left">
            <div className="modal-login-head">
              Welcome back to Twin
            </div>
            <div className="modal-login-text">
              A gift for the restoration of the same mayzhe tviy!
              Memorize the form and cut off 200 free rounds in 10 days,
              a 200% bonus on your first deposit and a non-cash sports bet!
            </div>
          </div>
          <div className="modal-login-right">
            <form
              autoComplete="on"
              className="modal-login-form"
              onSubmit={(e) => {
                e.preventDefault();
                loginHandler();
              }}
            >
              <div className="item-input-wrapper">
                <IonItem
                  className={'ion-floating-style' + (userNameSuccess === false ? ' error-input' : '')}
                >
                  <IonLabel position="stacked">Username</IonLabel>
                  <IonInput
                    autocomplete="username"
                    value={userName}
                    onIonChange={e => setUserName(e.detail.value)}
                    type='text'
                  ></IonInput>
                  <IonIcon slot="end" icon={userNameSuccess ? validIcon : userIcon}></IonIcon>
                </IonItem>
                {userNameSuccess === false && <div className="error-block">{errorText}</div>}
              </div>

              <div className="item-input-wrapper">
                <IonItem
                  className={'ion-floating-style' + (passwordSuccess === false ? ' error-input' : '')}
                >
                  <IonLabel position="stacked">Password</IonLabel>
                  <IonInput
                    autocomplete="current-password"
                    value={password}
                    onIonChange={e => setPassword(e.detail.value)}
                    type={passVisible ? 'text' : 'password'}
                  ></IonInput>
                  <IonIcon
                    className="pass-icon"
                    slot="end"
                    icon={(passwordSuccess && validIcon) || (passVisible ? passIconClose : passIconOpen)}
                    onClick={() => setPassVisible(!passVisible)}
                  ></IonIcon>
                </IonItem>

                {/* <div
                  className={'ion-floating-style' + (passwordSuccess === false ? ' error-input' : '')}
                >
                  <span position="stacked">Password</span>
                  <input value={password} onChange={e => setPassword(e.target.value)} placeholder='Password' className='field' type="password" />

                </div> */}
                {passwordSuccess === false && <div className="error-block">{errorText}</div>}
              </div>

              <div className="btn-container">
                <IonButton
                  className="btn-orange"
                  type="submit"
                >Log In
                </IonButton>
              </div>

              <div className="btn-container">
                <IonButton
                  className="form-login-btn-open-account button-solid btn-blue"
                  onClick={() => {
                    setIsOpen(false);
                    setSignUpOpen(true)
                  }}
                >Open Account
                </IonButton>
              </div>
            </form>
            <div className="modal-login-right-bottom">
              <IonItem lines="none">
                <IonCheckbox />
                <IonLabel>Remember me</IonLabel>
              </IonItem>

              <Link
                onClick={() => { setIsOpen(false); setRestoreOpen(true) }}
                to="/"
              >Forgot password?
              </Link>

            </div>
          </div>
        </div>
      </div>
    </IonModal>
  )
}

export default ModalLogin;
