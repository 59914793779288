import ReactApexChart from 'react-apexcharts';
import React, { useState, useEffect } from "react";
import moment from 'moment';

export default function Graph() {
  const [series, setSeries] = useState([{ name: "", data: [] }])
  const [options, setOptions] = useState({});
  const seriesDataLength = series[0].data.length;
  const topGames = [
    {
      amount: "1",
      date: "2021-01-14",
    },
    {
      amount: "5",
      date: "2021-01-15",
    },
    {
      amount: "10",
      date: "2021-01-16",
    },
    {
      amount: "7",
      date: "2021-01-17",
    },
    {
      amount: "6",
      date: "2021-01-18",
    },
    {
      amount: "3",
      date: "2021-01-19",
    },
    {
      amount: "1",
      date: "2021-01-20",
    },
    {
      amount: "11",
      date: "2021-01-21",
    },
    {
      amount: "4",
      date: "2021-01-22",
    }
  ]

  useEffect(() => {
    if (series.length === 1) {
      let gamesNames = [];
      topGames.forEach((item) => {
        gamesNames.push(moment(item.date).format('DD/MM'));
      });
      setSeries([
        {
          name: "Deposit",
          data: topGames.map(el => el.amount)
        },
      ]);
      let currentOptions = {
        chart: {
          height: 400,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: gamesNames,
        },
        colors: ['#FFA441'],
        stroke: {
          curve: 'smooth',
          width: 2
        },
        grid: {
          strokeDashArray: 7,
          borderColor: '#6955A0',
        },
        markers: {
          size: 0
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: "vertical",
            colorStops: [
              [
                {
                  offset: 0,
                  color: '#584296',
                  opacity: 1
                },
                {
                  offset: 0.1,
                  color: '#584296',
                  opacity: 50
                },
                {
                  offset: 100,
                  color: '#1B0A4A',
                  opacity: 1
                }
              ]
            ]
          }
        },
      };
      setOptions(currentOptions);
    }

  }, [seriesDataLength])

  return (
    <div className='chart-bars'>
      {seriesDataLength > 0 && <ReactApexChart options={options} series={series} type="area" height={350} />}
    </div>
  );
}
