import React, { useState } from 'react';
import { IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react';
import { useAtom } from 'jotai';
import { mobileMenuButton, modalCashier } from '../state';
import TabDeposit from './cashier/TabDeposit';
import TabWithdraw from './cashier/TabWithdraw';
import TabTip from './cashier/TabTip';
import TabFree from './cashier/TabFree';
import closeModal from '../images/icons-other/close-modal.svg';

const ModalCashier = () => {
  const [cashierOpen, setCashierOpen] = useAtom(modalCashier);
  const [, setMenuBtn] = useAtom(mobileMenuButton);

  const [cashierTab, setCashierTab] = useState('deposit');

  return (
    <IonModal
      isOpen={cashierOpen}
      cssClass="modal"
      onDidDismiss={() => { setCashierOpen(false); setMenuBtn('home') }}
    >
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">Сashier</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => { setCashierOpen(false); setMenuBtn('home') }}
          />
        </div>
        <div className="modal-content custom-scroll">
          <IonSegment
            scrollable
            className="modal-tabs"
            mode="md"
            value={cashierTab}
            onIonChange={e => setCashierTab(e.detail.value)}
          >
            <IonSegmentButton
              value="deposit"
              scrollable
            >
              <IonLabel>Deposit</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="withdraw">
              <IonLabel>Withdraw</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="tip">
              <IonLabel>Tip</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="free">
              <IonLabel>Free</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-hl"></div>

          {cashierTab === 'deposit' && <TabDeposit />}
          {cashierTab === 'withdraw' && <TabWithdraw />}
          {cashierTab === 'tip' && <TabTip />}
          {cashierTab === 'free' && <TabFree />}
        </div>
      </div>

    </IonModal>
  )
}

export default ModalCashier;
