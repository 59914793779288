import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { mobileMenuButton, notificationsMobile, tempNotificationData } from '../state';

const NotificationsMobile = () => {
  const [isOpen, setIsOpen] = useAtom(notificationsMobile);
  const [data,] = useAtom(tempNotificationData);
  const [menuBtn, setMenuBtn] = useAtom(mobileMenuButton);


  useEffect(() => {
    const handleClickOutside = (event) => {
      const modal = document.getElementById('notifications-window');
      const menuBottom = document.getElementById('mobile-menu-bottom');
      if (!event.path.includes(modal) && !event.path.includes(menuBottom)) {
        setIsOpen(false);
        setMenuBtn('home')
      };
    }

    if (menuBtn === 'notifications') {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [menuBtn, setMenuBtn, setIsOpen]);



  return (
    <div id="notifications-window" className={`notifications ${isOpen && 'open'}`}>
      <div className="notifications-header">Notifications</div>
      <div className="notifications-body">
        {data
          .map((item, i) =>
            <div className={`notifications-card ${item.isNew && 'new'}`} key={i}>
              <div className="notifications-body-top">
                <div className="notifications-time">{item.time}</div>
                <div className={`notifications-new ${item.isNew && 'new'}`}></div>
              </div>
              <div className="notifications-text">{item.text}</div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default NotificationsMobile;
