import React, { useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import numberOne from '../images/icons-numbers/1-middle.png';
import numberTwo from '../images/icons-numbers/2-middle.png';
import chestIcon from '../images/chest.png';
import signIcon from '../images/icons-other/sign.svg';
import bitcoin from '../images/icons-round/bitcoin.svg';


const tempTableData = [
  {
    user: 'elcachew2020',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Kerish23',
    number: '708',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Leonard1 - 2020',
    number: '12,053',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'NRock1',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'rjbbvjn',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Luisa',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Edvard12345',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Elsee65367',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Elsee65367',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
  {
    user: 'Elsee65367',
    number: '134',
    currency: '$',
    cash: '1888.16'
  },
];

const Raffle = (props) => {
  /*eslint-disable*/
  const [tableData, setTableData] = useState(tempTableData);
  /*eslint-enable*/
  return (
    <IonPage>
      <IonContent>
        <Header logoutHandler={props.logoutHandler} />
        <div className="banner-container bg2">
          <div className="banner-content">
            <div className="banner-content-text big-text">
              <span className="text-purple">$20,000</span> Galaxy haunted <span className="text-transparent">house</span>
              <div className="small-text">Winners have been drawn</div>
            </div>
            <div className="tickets-container flex">
              <div className="ticket tickets-winners">
                <div className="ticket-count">5</div>
                <div className="ticket-label">Winners</div>
              </div>
              <div className="ticket your-tickets">
                <div className="ticket-count">10</div>
                <div className="ticket-label">Your tickets</div>
              </div>
            </div>
          </div>
        </div>
        <main className="main-body static-body">
          <div className="body-content">
            <div className="info-container">
              <div className="info-container-heading">How to Enter</div>
              <div className="info-container-list">
                <div className="info-container-list-item">
                  <div className="info-container-list-item-image">
                    <img src={numberOne} alt="number" />
                  </div>
                  <div className="info-container-list-item-label">For every $10 wagered on Play’n Go slots, you earn 1 tickets</div>
                </div>
                <div className="info-container-list-item">
                  <div className="info-container-list-item-image">
                    <img src={numberTwo} alt="number" />
                  </div>
                  <div className="info-container-list-item-label">Playnig the Reactoonz 2 slots earns you 2x as many tickets</div>
                </div>
              </div>
              <div className="info-container-text">
                Once the raffle begins, your ticket count will automatically increase with every $10 in qualifled wagers until the raffle has ended. At the end of the coundown all tickets are etered into a pool and 5 winners are drawn at random, to split the $20,000 prize. Earn as manny tickets as prossible to improve your chances to win! If you win, your funds will automatically be added to your roobret balance and you’ll be notified by email.
              </div>
            </div>
            <div className="big-container">
              <div className="big-container-top">
                <img className="big-container-top-image" src={chestIcon} alt="chest" />
                <div className="big-container-title">Raffle Winners</div>
              </div>

              <table className="table">
                <tbody>
                  {tableData.map((item, i) =>
                    <tr key={i}>
                      <td className="small-td">
                        <div className="table-number-container">
                          <img src={require(`../images/icons-numbers/${i + 1}.png`)} alt="number" />
                          {/* {++i} */}
                        </div>
                      </td>
                      <td className="big-td">
                        <div className="table-user-container">
                          <span>{item.user}</span>&nbsp;—
                          <img src={signIcon} alt="number" />
                          {item.number}
                        </div>
                      </td>
                      <td className="currency-td">
                        <div className="table-money-container">
                          {item.currency}{item.cash}
                          <img src={bitcoin} alt="currency icon" />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </main>
        <Footer />
      </IonContent>
    </IonPage>
  )
}

export default Raffle;
