import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Header from '../components/Header';
import LiveFeed from '../components/LiveFeed';
import Footer from '../components/Footer';

const TermsOfService = (props) => {
  return (
    <IonPage>
      <IonContent>
        <Header logoutHandler={props.logoutHandler} />
        <main className="main-body tos">
          <div className="body-content">
            <div className="info-container">
              <div className="info-container-title">Terms of Service</div>
              <div className="info-container-subtitle">1. ACCEPTANCE OF THE TERMS OF SERVICE</div>
              <p>
                These terms of service are entered into by and between You and TEKHOU5 LIMITED ("Company", "we" or "us"), organized under the laws of Belize, having its registered address at 9 Barrack Road, Belize City, Belize. The following terms of service, (collectively, these "ToS"), govern your access to and use of the Website www.roobet.com. ("website"), including any content, functionality, and services offered on or through the website, whether as a guest or a registered user. You acknowledge and agree with our terms and conditions in with and without reservation.
              </p>
              <p>
                These ToS come into force as soon as You access the website, by doing which you signify to roobet.com that You have read these ToS and accept them. By using any page of the Websites You signify that You agree entirely with these ToS and our Privacy Policy. If You do not want to agree to these ToS or the Privacy Policy, You must not access or use the website.
              </p>
              <p>
                roobet.com is licensed and authorized by the Government of Curacao and operates under the Master License of Gaming Services Provider, N.V. #365/JAZ License Number: Sub-License GLH-OCCHKTW0710102018.Verification Here
              </p>
              <p>
                Your access to and use of the website, as well as all services on it, is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all users, visitors and others who access or the Service. Any use of Service without acceptance of the Terms of Service is not granted.
              </p>
              <p>
                By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Website.
              </p>
              <p>
                By accessing the Website or using any of the Services You confirm to be 18 years or older. If You are not 18 years or older You may not access roobet.com. Additionally, You may not access the website, if you are below the age of 18.
              </p>
              <p>
                Roobet.com is a gambling website, do not connect to it if you are not allowed to gamble for legal, health, moral, religious or psychological reasons. By creating an Account on Our Website, you confirm that You are in a state of mind which allows you to responsible gamble, in physical conditions to make clear decisions and see their consequences, as well as legally allowed to gamble.
              </p>
              <p>
                Roobet offers mainly a chat and gambling Services, Roobet does not offer any currency exchange Services, you may only withdraw in the same currency as You deposited.
              </p>
              <p>
                Your access to Roobet.com and any use of the Services provided on it is also conditioned on Your acceptance of and compliance with the Privacy Policy of Our Company and our Anti Money Laundering Policy- AML-Policy. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Website. Our AML- KYC Policy describes the policies and procedures to prevent Money Laundering on our Website. Please read Our AML-KYC Policy carefully before using Our Service.
              </p>
              <p>
                We grant You a non-exclusive, personal, non-transferable right to use Our Services on Roobet.com on any device able to connect the internet in your possession. All Games and Services will be only available online on Our Website. We may terminate your account at any point if you transfer Your personal account to another individual, or company, or other legal entity.
              </p>
              <div className="info-container-subtitle">2. YOUR OBLIGATIONS AS A PLAYER</div>
              <p>
                You hereby declare and warrant that:
              </p>
              <p>
                In the case of the identification of a minor player, the Account will be closed immediately.
              </p>
              <p>
                You will use this website and your roobet.com Account solely and exclusively for the purpose of your genuine participation in the games and not for any financial or other operations, your participation in the games will be strictly in Your personal non-professional capacity for recreational and entertainment reasons only;
              </p>
              <p>
                People living or having a permanent or temporary residence in these countries are not entitled to register the player's account on roobet.com, or the use of service roobet.com:
              </p>
              <p>
                Aruba, Austria, Bonaire, Curacao, French Guiana, French Polynesia, Germany, Guernsey, Gibraltar, Hungary, Isle of Man, Jersey, Liechtenstein, Luxembourg, Martinique, Reunion, Slovakia, Mayotte, Netherlands, United States, United Kingdom, Denmark, Turkey, Poland.
              </p>
              <p>
                Residents from the aforementioned territories are prohibited from opening an account or in any way use the gaming services offered by roobet.com.
              </p>
              <p>
                In the jurisprudence of your country or the country where you are currently staying gambling and/or games of chance, they may be prohibited. You are responsible for making sure such restrictions and / or bans in their home country or the country in which he/she resides. It is up to you to ensure that you act in accordance with the laws of your home country or the country where You currently reside. You have no right to use the services as an anonymizing proxy, VPN or the like in order to bypass the law resulting from the blockade by roobet.com countries. In case of violation of these principles, roobet.com reserves the right to block and/or immediately close the account and you lose all winnings/balance.
              </p>
              <p>
                You warrant that all information that You provide to roobet.com during the term of validity of this agreement is true, complete, and correct and that you shall immediately notify roobet.com of any change of such information;
              </p>
              <p>
                You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from roobet.com.
              </p>
              <p>
                In case of violation of these terms, roobet.com reserves the right to block and/or immediately close the account and you lose all winnings/balance.
              </p>
              <div className="info-container-subtitle">2.2 User Accounts</div>
              <p>
                When You create an account on roobet.com, You must provide Us information which must be accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Services and Website.
              </p>
              <p>
                You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service, Our Website or a Third-Party Social Media Service.
              </p>
              <p>
                You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account. All actions taken from your Account fall under Your responsibility.
              </p>
              <p>
                You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, racist, vulgar or obscene. If a Username is chosen, which violates our Terms of Service We may terminate your Account without notice or delay. All balance / Winnings on such an Account will be frozen.
              </p>
              <p>
                If any behaviors are shown which disrespects or insults other users or our Staff We may terminate your Account without notice of delay according to our judgment. All balance/ Winnings on such an Account will be frozen.
              </p>
              <p>
                You may not sell your account or give it any other natural or juristically person. If You give Your Account to someone else this may result in immediate termination of Your Account on Our Website.
              </p>
              <p>
                You may not sell Roobet Balance or any of Our Services to any other natural or juristically person. If you sell Our Services or Roobet Balance to someone else this may result in immediate termination of Your Account on Our Service.
              </p>
            </div>
            <LiveFeed />
          </div>
        </main>
        <Footer />
      </IonContent>
    </IonPage>
  )
}

export default TermsOfService;
