import React, { useState } from 'react';
import { useAtom } from 'jotai';
import {
  modalCashier, modalHistory, modalLogin,
  modalPreference, modalSignUp, modalAffiliate, tokenValue, userData
} from '../state.js';
import { Link } from 'react-router-dom';
import { IonButton, IonIcon, IonItem, IonPopover, IonSelect, IonSelectOption } from '@ionic/react';
import Notifications from './Notifications.js';
import logo from '../images/logo.svg';
import notice from '../images/icons-header/bell.svg';
import bitcoinIcon from '../images/icons-round/bitcoin.svg';
import ethereumIcon from '../images/icons-round/ethereum.svg';
import arrow from '../images/icons-input/select-dropdown.svg';
import cashierIcon from '../images/icons-header/sort-three.svg';
import userIcon from '../images/icons-header/account.svg';
import affiliateIcon from '../images/icons-header/chart-line.svg';
import historyIcon from '../images/icons-header/history.svg';
import supportIcon from '../images/icons-header/headset-one.svg';
import logoutIcon from '../images/icons-header/logout.svg';



const Header = ({ logoutHandler }) => {
  const [token,] = useAtom(tokenValue);
  const [user,] = useAtom(userData);
  const [, setSignUpOpen] = useAtom(modalSignUp);
  const [, setLoginOpen] = useAtom(modalLogin);
  const [, setCashierOpen] = useAtom(modalCashier);
  const [, setHistoryOpen] = useAtom(modalHistory);
  const [, setAffiliateOpen] = useAtom(modalAffiliate);
  const [, setPreferenceOpen] = useAtom(modalPreference);

  const [noticeDropdown, setNoticeDropdown] = useState({ showDropdown: false, event: undefined });
  const [userDropdown, setUserDropdown] = useState({ showDropdown: false, event: undefined });
  const [mobileСurrency, setMobileСurrency] = useState('bitcoin');



  return (
    <header className="header">
      <div className="header-content">
        <Link to="/home" className="header-logo-container">
          <img src={logo} alt="logo" />
        </Link>
        <nav className="header-nav">
          <div className="header-nav-item">
            <div className="header-nav-marker"></div>
            <Link to="/">Promo</Link>
          </div>
          <div className="header-nav-item">
            <div className="header-nav-marker"></div>
            <Link to="/">AffiLiate</Link>
          </div>
          <div className="header-nav-item">
            <div className="header-nav-marker"></div>
            <Link to="/">Free Play</Link>
          </div>
          <div className="header-nav-item">
            <div className="header-nav-marker"></div>
            <Link to="/">Link Feed</Link>
          </div>
          <div className="header-nav-item">
            <div className="header-nav-marker"></div>
            <Link to="/">Galaxy Wards</Link>
          </div>
        </nav>

        {!token &&
          <div className="header-unauth-container">
            <IonButton
              className="header-unauth-login-btn"
              onClick={() => setLoginOpen(true)}
            >
              Log In
            </IonButton>
            <IonButton
              className="header-unauth-signup-btn"
              onClick={() => setSignUpOpen(true)}
            >
              Sign Up
            </IonButton>
          </div>
        }

        {token &&
          <div className="header-auth-container">
            <div className="header-balance-container">
              <div className="header-balance-bitcoin-container header-balance-item">
                <img src={bitcoinIcon} alt="bitcoin" />
                <div className="header-balance-bitcoin-value header-balance-value">
                  $0,00
                </div>
              </div>
              <div className="header-balance-vl"></div>
              <div className="header-balance-etherium-container header-balance-item">
                <img src={ethereumIcon} alt="ethereum" />
                <div className="header-balance-ethereum-value header-balance-value">$0,00</div>
              </div>
            </div>

            {/* only mobile */}
            <IonItem
              lines="none"
              className="header-balance-container-mobile"
            >
              <IonIcon slot="start" icon={mobileСurrency === 'bitcoin' ? bitcoinIcon : ethereumIcon} className="header-balance-currency"></IonIcon>
              <IonSelect
                interface="popover"
                value={mobileСurrency}
                onIonChange={(e) => setMobileСurrency(e.detail.value)}
              >
                <IonSelectOption
                  className="select-value-option bitcoin"
                  value="bitcoin"
                >
                  $0,00
                </IonSelectOption>
                <IonSelectOption
                  className="select-value-option ethereum"
                  value="etherium"
                >
                  $10,00
                </IonSelectOption>
              </IonSelect>
              <IonIcon slot="end" icon={arrow} className="header-balance-arrow"></IonIcon>
            </IonItem>

            <div className="header-deposit-btn-container btn-container">
              <IonButton onClick={() => setCashierOpen(true)} className="btn-orange">Deposit</IonButton>
            </div>
            <div className="header-notice">
              <img
                className="header-notice-img"
                src={notice}
                alt="bell"
                onClick={(e) => {
                  e.persist();
                  setNoticeDropdown({ showDropdown: true, event: e })
                }}
              />
              <div className="header-notice-badge"></div>
            </div>
            <div
              className="header-user-container"
              onClick={(e) => {
                e.persist();
                setUserDropdown({ showDropdown: true, event: e })
              }}
            >
              <div className="header-username">{user.first_name}</div>
              <img className="arrow" src={arrow} alt="arrow" />
            </div>

            <IonPopover
              cssClass="header-notice-dropdown"
              event={noticeDropdown.event}
              isOpen={noticeDropdown.showDropdown}
              onDidDismiss={() => setNoticeDropdown({ showDropdown: false, event: undefined })}
            >
              <Notifications />
            </IonPopover>

            <IonPopover
              cssClass="header-user-dropdown"
              event={userDropdown.event}
              isOpen={userDropdown.showDropdown}
              onDidDismiss={() => setUserDropdown({ showDropdown: false, event: undefined })}
            >
              <div className="header-user-dropdown-greating">Hey {user.first_name}!</div>
              <div className="header-user-dropdown-menu">
                <div
                  className="header-user-dropdown-menu-item"
                  onClick={(e) => {
                    e.persist();
                    setUserDropdown({ showDropdown: false, event: e });
                    setCashierOpen(true);
                  }}
                >
                  <img src={cashierIcon} alt="cashier" />
                  <div className="header-user-dropdown-menu-item-label">Cashier</div>
                </div>
                <div
                  className="header-user-dropdown-menu-item"
                  onClick={(e) => {
                    e.persist();
                    setUserDropdown({ showDropdown: false, event: e });
                    setPreferenceOpen(true);
                  }}
                >
                  <img src={userIcon} alt="account" />
                  <div
                    className="header-user-dropdown-menu-item-label"
                  >
                    Account
                  </div>
                </div>
                <div
                  className="header-user-dropdown-menu-item"
                  onClick={(e) => {
                    e.persist();
                    setUserDropdown({ showDropdown: false, event: e });
                    setAffiliateOpen(true);
                  }}
                >
                  <img src={affiliateIcon} alt="affiliate" />
                  <div className="header-user-dropdown-menu-item-label">Affiliate</div>
                </div>
                <div
                  className="header-user-dropdown-menu-item"
                  onClick={(e) => {
                    e.persist();
                    setUserDropdown({ showDropdown: false, event: e });
                    setHistoryOpen(true);
                  }}

                >
                  <img src={historyIcon} alt="history" />
                  <div className="header-user-dropdown-menu-item-label">History</div>
                </div>
                <div className="header-user-dropdown-menu-item">
                  <img src={supportIcon} alt="live support" />
                  <div className="header-user-dropdown-menu-item-label">Live Support</div>
                </div>
                <div
                  className="header-user-dropdown-menu-item"
                  onClick={() => logoutHandler()}
                >
                  <img src={logoutIcon} alt="log out" />
                  <div className="header-user-dropdown-menu-item-label">Log Out</div>
                </div>
              </div>
            </IonPopover>
          </div>
        }
      </div>
    </header>
  )
}

export default Header;
