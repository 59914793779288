import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import Header from '../components/Header';
import '../css/page404.css';

const Page404 = (props) => {
  return (
    <IonPage>
      <IonContent>
        <Header logoutHandler={props.logoutHandler} />
        <main className="page404-main">
          <div className="page404-main-inner">
            <div className="page404-transparent-text">Oh, ship</div>
            <div className="page404-big-text">404</div>
            <div className="page404-white-text">Well, we did it. We lost ya.</div>
            <div className="page404-white-text">Let’s getchu outta here.</div>
          </div>
        </main>
      </IonContent>
    </IonPage>
  )
}

export default Page404;
