import React, { useState } from 'react';
import { IonItem, IonInput, IonLabel, IonSegment, IonButton, IonSegmentButton, IonIcon, IonToggle, IonModal } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalPreference } from '../state';
import { Link } from 'react-router-dom';
import closeModal from '../images/icons-other/close-modal.svg';
import userIcon from '../images/icons-header/account.svg';
import mailIcon from '../images/icons-input/mail.svg';
import calendarIcon from '../images/icons-input/calendar.svg';
import localIcon from '../images/icons-input/local.svg';
import passIconClose from '../images/icons-input/pass-invisible.svg';
import passIconOpen from '../images/icons-input/pass-visible2.svg';
import userImage from '../images/icons-round/user-image.png';
import fileUploading from '../images/icons-action/file-uploading.svg';


const ModalPreference = () => {
  const [isOpenModal, setIsOpenModal] = useAtom(modalPreference);

  const [preferenceTab, setPreferenceTab] = useState('general');
  const [passVisible, setPassVisible] = useState(false);

  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">Preferences</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
          />
        </div>
        <div className="modal-content modal-preference custom-scroll">
          <IonSegment
            scrollable
            className="modal-tabs"
            mode="md"
            value={preferenceTab}
            onIonChange={e => setPreferenceTab(e.detail.value)}
          >
            <IonSegmentButton value="general">
              <IonLabel>General</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="security">
              <IonLabel>Security</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="privacy">
              <IonLabel>Privacy</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="verification">
              <IonLabel>Verification</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="blocked">
              <IonLabel>Blocked users</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-hl"></div>

          {preferenceTab === 'general' &&
            <div className="modal-content-body">
              <div className="modal-body-item">

                <div className="modal-preference-container">
                  <div className="modal-body-item-heading">Referrer</div>
                  <div className="modal-referrer-container flex">
                    <div className="modal-referrer-input">
                      <div className="modal-referrer-label">Referred by</div>
                      <IonItem className="ion-floating2-style" lines="none">
                        <IonLabel position="floating">N/A</IonLabel>
                        <IonInput
                          value=""
                          onIonChange={e => console.log(e.detail.value)}
                        ></IonInput>
                      </IonItem>
                    </div>
                    <div className="btn-container">
                      <IonButton
                        className="btn-orange"
                        type="submit"
                        disabled={true}
                      >
                        Send
                        </IonButton>
                    </div>
                  </div>
                </div>

                <div className="modal-preference-container">
                  <div className="modal-body-item-heading">Alternate Login Methods</div>
                  <div className="modal-alternate-description">
                    Login even faster by connecting your account to one or more of the available services below
                  </div>
                  <div className="modal-app-container flex">
                    <div className="modal-app-item modal-app-google">
                      <div className="modal-app-name before flex">
                        <span>Google</span>
                      </div>
                      <div className="modal-app-address before flex">
                        <span>ulia12345@gmai.com</span>
                        <div className="modal-app-exit"></div>
                      </div>
                    </div>
                    <div className="modal-app-item modal-app-stream">
                      <div className="modal-app-name before flex">
                        <span>Sream</span>
                      </div>
                      <Link to="/" className="modal-action">Link Steam Account</Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          }

          {preferenceTab === 'security' &&
            <div className="modal-content-body">
              <div className="modal-security flex">

                <div className="modal-left-container">
                  <div className="modal-preference-container">
                    <div className="modal-body-item-heading">Recovery</div>
                    <div className="modal-security-fields">
                      <IonItem className="ion-floating2-style" lines="none">
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                          value=""
                          onIonChange={e => console.log(e.detail.value)}
                        >
                        </IonInput>
                        <IonIcon slot="end" icon={mailIcon}></IonIcon>
                      </IonItem>
                      <div className="btn-container">
                        <IonButton
                          className="btn-blue"
                          type="submit"
                        >
                          Save
                        </IonButton>
                      </div>
                    </div>
                  </div>

                  <div className="modal-preference-container">
                    <div className="modal-body-item-heading">Change Password</div>
                    <div className="modal-security-fields">
                      <IonItem className="ion-floating2-style">
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput type={passVisible ? 'text' : 'password'}></IonInput>
                        <IonIcon
                          className="pass-icon"
                          slot="end"
                          icon={passVisible ? passIconClose : passIconOpen}
                          onClick={() => {
                            setPassVisible(!passVisible);
                            console.log(passVisible);
                          }}
                        ></IonIcon>
                      </IonItem>
                      <IonItem className="ion-floating2-style">
                        <IonLabel position="floating">Confirm password</IonLabel>
                        <IonInput type={passVisible ? 'text' : 'password'}></IonInput>
                        <IonIcon
                          className="pass-icon"
                          slot="end"
                          icon={passVisible ? passIconClose : passIconOpen}
                          onClick={() => {
                            setPassVisible(!passVisible);
                            console.log(passVisible);
                          }}
                        ></IonIcon>
                      </IonItem>
                      <div className="btn-container">
                        <IonButton
                          className="btn-orange"
                          type="submit"
                        >
                          Change password
                        </IonButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-right-container">
                  <div className="modal-preference-container">
                    <div className="modal-body-item-heading">Two-factor Authentication</div>
                    <div className="tip-form-bottom-left">
                      <IonItem lines="none">
                        <IonToggle
                          mode="ios"
                          onIonChange={e => console.log(e.detail.checked)}
                        />
                        <IonLabel>
                          <div>Buy & Send</div>
                          <div>Once you've signed up you can now link credit cards
                        or bank accounts to purchase BTC/ETH. Once you make a purchase, you are then able to send BTC/ETH to your corresponding addresses below to deposit on Gotowin.</div>
                        </IonLabel>
                      </IonItem>
                    </div>
                  </div>
                  <div className="modal-preference-container">
                    <div className="modal-body-item-heading">Multi-Device Logout</div>
                    <div className="modal-preference-text">
                      If you think your account may have been compromised, reset your password and then hit the button below to end all current active sessions including this one.
                    </div>
                  </div>
                  <div className="modal-preference-container">
                    <div className="modal-security-actions flex">
                      <Link to="/" className="modal-action">View Recent Session</Link>
                      <Link to="/" className="modal-action">Logout Every Where</Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          }

          {preferenceTab === 'privacy' &&
            <div className="modal-content-body">
              <div className="modal-body-item">

                <div className="modal-preference-container">
                  <div className="modal-body-item-heading">Two-factor Authentication</div>
                  <div className="tip-form-bottom-left">
                    <IonItem lines="none">
                      <IonToggle
                        mode="ios"
                        onIonChange={e => console.log(e.detail.checked)}
                        checked={true}
                      />
                      <IonLabel>
                        <div>Display Total Wagers</div>
                        <div>Allow other players to view the total amount of wagers you've ever made</div>
                      </IonLabel>
                    </IonItem>
                  </div>
                  <div className="tip-form-bottom-left">
                    <IonItem lines="none">
                      <IonToggle
                        mode="ios"
                        onIonChange={e => console.log(e.detail.checked)}
                      />
                      <IonLabel>
                        <div>Display Amount Wagered</div>
                        <div>Allow other players to view your all time wagered amount</div>
                      </IonLabel>
                    </IonItem>
                  </div>
                  <div className="tip-form-bottom-left">
                    <IonItem lines="none">
                      <IonToggle
                        mode="ios"
                        onIonChange={e => console.log(e.detail.checked)}
                      />
                      <IonLabel>
                        <div>Mask Sensitive Information</div>
                        <div>Mask your personal settings (e.g. your email on your account settings). Perfect for content creators wanting to hide personal information from their videos.</div>
                      </IonLabel>
                    </IonItem>
                  </div>
                </div>

                <div className="modal-preference-container">
                  <div className="modal-body-item-heading">Game Feed</div>
                  <div className="tip-form-bottom-left">
                    <IonItem lines="none">
                      <IonToggle
                        mode="ios"
                        onIonChange={e => console.log(e.detail.checked)}
                        checked="true"
                      />
                      <IonLabel>
                        <div>Incognito Mode</div>
                        <div>Hide your identity when placing a bet and when being displayed on the live game feed</div>
                      </IonLabel>
                    </IonItem>
                  </div>
                </div>

                <div className="modal-preference-container">
                  <div className="modal-body-item-heading">Account Closure</div>
                  <div className="modal-preference-text">
                    You may close your account by clicking the "Close My Account" button below. All of your data will be permanently wiped and cannot be restored in the future (Including any VIP perks).
                  </div>
                </div>

              </div>
            </div>
          }

          {preferenceTab === 'verification' &&
            <div className="modal-content-body">
              <div className="modal-verification flex">

                <div className="modal-left-container">
                  <div className="modal-preference-container">
                    <div className="modal-body-item-heading">Personal Info</div>
                    <div className="modal-security-fields">
                      <IonItem className="ion-floating2-style">
                        <IonLabel position="floating">Name</IonLabel>
                        <IonInput></IonInput>
                        <IonIcon slot="end" icon={userIcon}></IonIcon>
                      </IonItem>
                      <IonItem className="ion-floating2-style">
                        <IonLabel position="floating">Data of brith</IonLabel>
                        <IonInput></IonInput>
                        <IonIcon slot="end" icon={calendarIcon}></IonIcon>
                      </IonItem>
                      <IonItem className="ion-floating2-style">
                        <IonLabel position="floating">Address</IonLabel>
                        <IonInput></IonInput>
                        <IonIcon slot="end" icon={localIcon}></IonIcon>
                      </IonItem>
                      <div className="btn-container">
                        <IonButton
                          className="btn-blue"
                          type="submit"
                        >
                          Save
                        </IonButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-right-container">
                  <div className="modal-body-item-heading">Documents</div>
                  <div className="modal-preference-text">
                    Please upload two documents below. For Proof of Identity, please send us a photo of either of your ID card, driver's license or passport. For Proof of Address, please send us a utility bill
                    or similar with your name and address on it from the past 3 months.
                    <div>File types accepted: .png, .jpg, .pdf</div>
                  </div>
                  <div className="modal-verify-item">
                    <div className="modal-body-item-heading">Proof of Identity</div>
                    <div className="file-zone flex">
                      <IonButton className="btn-file" type="submit">
                        <IonIcon slot="start" icon={fileUploading} />
                        Select a file
                    </IonButton>
                      <div className="btn-file-info">No file selected</div>
                      <Link to="/" className="modal-action">Upload</Link>
                    </div>
                  </div>
                  <div className="modal-verify-item">
                    <div className="modal-body-item-heading">Proof Addresss</div>
                    <div className="file-zone flex">
                      <IonButton className="btn-file" type="submit">
                        <IonIcon slot="start" icon={fileUploading} />
                        Select a file
                    </IonButton>
                      <div className="btn-file-info">No file selected</div>
                      <Link to="/" className="modal-action">Upload</Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          }
          {/* Blocked Users Empty */}
          {/* {preferenceTab === 'blocked' &&
            <div className="modal-content-body">
              <div className="modal-body-item modal-blocked-empty">
                You have not muted anyone
              </div>
            </div>
          }*/}

          {preferenceTab === 'blocked' &&
            <div className="modal-content-body">
              <div className="modal-body-item">
                <div className="modal-body-item-heading">Blocked users </div>
                <div className="blocked-users-list">

                  <div className="blocked-users-item flex">
                    <div className="blocked-user-image">
                      <img src={userImage} alt="Avatar" />
                    </div>
                    <div className="blocked-user-name">Zatkomc2</div>
                    <div className="blocked-user-action">
                      <Link to="/" className="modal-action">Unblock</Link>
                    </div>
                  </div>

                  <div className="blocked-users-item flex">
                    <div className="blocked-user-image">
                      <img src={userImage} alt="Avatar" />
                    </div>
                    <div className="blocked-user-name">Zatkomc2</div>
                    <div className="blocked-user-action">
                      <Link to="/" className="modal-action">Unblock</Link>
                    </div>
                  </div>

                  <div className="blocked-users-item flex">
                    <div className="blocked-user-image">
                      <img src={userImage} alt="Avatar" />
                    </div>
                    <div className="blocked-user-name">Zatkomc2</div>
                    <div className="blocked-user-action">
                      <Link to="/" className="modal-action">Unblock</Link>
                    </div>
                  </div>

                  <div className="blocked-users-item flex">
                    <div className="blocked-user-image">
                      <img src={userImage} alt="Avatar" />
                    </div>
                    <div className="blocked-user-name">Zatkomc2</div>
                    <div className="blocked-user-action">
                      <Link to="/" className="modal-action">Unblock</Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </IonModal>
  )
}

export default ModalPreference;
