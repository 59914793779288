import React, { useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAtom } from 'jotai';
import {
  tokenValue, countriesList, toastValue, toastTextValue,
  errorToastValue, errorToastTextValue, currenciesList,
  requiredFieldErrorText, userData, gamesList, tagsList,
  openGameId, loadingMain, defGameUrl, defHtml, modalGame,
  langValue
} from './state';
import * as API from './api/api.js';
import history from './utils/history';
import Home from './pages/Home';
import ModalSignUp from './components/ModalSignUp.js';
import ModalLogin from './components/ModalLogin.js';
import ModalHistory from './components/ModalHistory.js';
import ModalPreference from './components/ModalPreference.js';
import ModalAffiliate from './components/ModalAffiliate.js';
import ModalError from './components/ModalError.js';
import Page404 from './pages/Page404.js';
import TermsOfService from './pages/TermsOfService.js';
import Raffle from './pages/Raffle.js';
import Dev from './pages/Dev';
import ModalRestore from './components/ModalRestore.js';
import ModalCashier from './components/ModalCashier.js';
import ModalGalaxyWards from './components/ModalGalaxyWards.js';
import MobileMenu from './components/MobileMenu.js';
import NotificationsMobile from './components/NotificationsMobile.js';
import AccountMobile from './components/AccountMobile.js';
import ModalGame from './components/ModalGame.js';
import './css/main.css';
import './css/home.css';
import './css/modal-cashier.css';
import './css/footer.css';
import './css/graph.css'
import './css/header.css';
import './css/mobile-menu.css';
import './css/modal-affiliate.css';
import './css/modal-error.css';
import './css/modal-galaxy-wards.css';
import './css/modal-history.css';
import './css/modals-auth.css';
import './css/modal-preference.css';
import './css/modals-auth.css';
import './css/raffle.css';
import './css/dev-darya.css';
import './css/terms-of-service.css';
import './theme/variables.css';
import './css/media-anton.css';
import './css/media-darya.css';
import './css/media-darya4k.css';
import './css/media.css';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import Loading from './components/LoadingMain';
import { getFP } from './utils/functions';


const App = () => {
  // Data
  const [token, setToken] = useAtom(tokenValue);
  const tokenInlocalStorage = localStorage.getItem("token");
  const [lang,] = useAtom(langValue);
  const [user, setUser] = useAtom(userData);
  const [, setCountries] = useAtom(countriesList);
  const [, setCurrencies] = useAtom(currenciesList);
  const [, setGames] = useAtom(gamesList);
  const [, setTags] = useAtom(tagsList);

  // Modal Game
  const [, setIsOpenGame] = useAtom(modalGame);
  const [, setOpenGame] = useAtom(openGameId);
  const [, setGameUrl] = useAtom(defGameUrl);
  const [, setHtml] = useAtom(defHtml);

  // Notifications & Errors
  const [toast, setToast] = useAtom(toastValue);
  const [toastText,] = useAtom(toastTextValue);
  const [errorToast, setErrorToast] = useAtom(errorToastValue);
  const [errorToastText,] = useAtom(errorToastTextValue);
  const requiredFieldErrorValue = useAtom(requiredFieldErrorText)[0]

  //Loading
  const [, setShowLoading] = useAtom(loadingMain);


  //check for token in localStorage
  useEffect(() => {
    if (tokenInlocalStorage) {
      setToken(tokenInlocalStorage);
    }
  }, [tokenInlocalStorage, setToken]);


  // get games
  useEffect(() => {
    if (tokenInlocalStorage || token) {
      API.getGames(tokenInlocalStorage)
        .then(res => setGames(res.listGames))
        .catch(error => console.log(error))
    } else {
      API.getGames('')
        .then(res => setGames(res.listGames))
        .catch(error => console.log(error))
    }
  }, [setGames]);


  // get tags
  useEffect(() => {
    API.getTags()
      .then(res => {
        setTags(res.listTags);
      })
  }, [setTags]);



  // get userInfo
  useEffect(() => {
    if (token) {
      API.getInfo(token)
        .then(res => {
          setUser(res.player);
        })
        .catch(error => {
          console.log(error);
        })
    }
  }, [token, setUser])


  // get countries
  useEffect(() => {
    API.getCountries()
      .then(res => {
        setCountries(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [setCountries]);


  // get currencies
  useEffect(() => {
    API.getCurrencies()
      .then(res => {
        setCurrencies(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [setCurrencies]);


  // check Fields 
  const checkField = (field, setFieldSuccess, setErrorText) => {
    if (!field) {
      setFieldSuccess(false);
      setErrorText(requiredFieldErrorValue);
    } else {
      setFieldSuccess(true);
    }
  }


  // check Password (repeat)
  const checkRepasswordField = (
    password, repassword,
    setPasswordSuccess, setRepasswordSuccess,
    setPasswordErrorText, setRepasswordErrorText,
  ) => {
    if (!repassword) {
      setRepasswordSuccess(false);
      setRepasswordErrorText(requiredFieldErrorValue);
    } else if (password !== repassword) {
      setRepasswordSuccess(false);
      setPasswordSuccess(false);
      setPasswordErrorText('Password mismatch');
      setRepasswordErrorText('Password mismatch');
    } else {
      setPasswordSuccess(true);
      setRepasswordSuccess(true);
    }
  }

  // log Out
  const logoutHandler = () => {
    API.logout(token).then(() => {
      setToken('');
      localStorage.removeItem('token');
      history.push('/home');
      document.location.reload();
    });
  }

  // show game
  function showGame(id) {
    setOpenGame(id);
    setShowLoading(true);
    const currency = user.currency_id;

    API.runGame(token, id, lang, currency)
      .then(res => {
        if (res.linkType === "URL") {
          setGameUrl(res.url);
        }
        if (res.linkType === "HTML") {
          setGameUrl("");
          setHtml(res.html);
        }
        setIsOpenGame(true);
      })
      .catch(error => {
        if (error.response.data.status === 422) {
          setGameUrl("false entry");
          setIsOpenGame(true);
        } else {
          console.log(error);
        }
      })
  }


  // show game's demo
  async function showDemoGame(id) {
    const result = await getFP();
    setOpenGame(id);
    setShowLoading(true);

    API.runGameDemo(token, id, lang, result)
      .then(res => {
        if (res.linkType === "URL") {
          setGameUrl(res.url);
        }
        if (res.linkType === "HTML") {
          setGameUrl("");
          setHtml(res.html);
        }
        setIsOpenGame(true);
      })
      .catch(err => {
        if (err.response.data.status === 422) {
          setGameUrl("false entry");
          setIsOpenGame(true);
        }
      })
  }



  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" when="(max-width: 40px)">

          <ModalSignUp
            checkField={checkField}
            checkRepasswordField={checkRepasswordField}
          />
          <ModalLogin />
          <ModalRestore
            checkField={checkField}
            checkRepasswordField={checkRepasswordField}
          />
          <ModalGalaxyWards />
          <ModalCashier />
          <ModalHistory />
          <ModalPreference />
          <ModalAffiliate />
          <ModalError />
          <MobileMenu />
          <NotificationsMobile />
          <AccountMobile logoutHandler={logoutHandler} />
          <ModalGame />
          <Loading />

          <IonToast
            cssClass='custom-toast wrong'
            mode="ios"
            position="top"
            isOpen={errorToast}
            onDidDismiss={() => setErrorToast(false)}
            message={errorToastText}
            duration={3000}
          />
          <IonToast
            cssClass='custom-toast good'
            mode="ios"
            position="top"
            isOpen={toast}
            onDidDismiss={() => setToast(false)}
            message={toastText}
            duration={3000}
          />

          <IonRouterOutlet id="main">
            <Route
              path="/home"
              render={() =>
                <Home
                  logoutHandler={logoutHandler}
                  showGame={showGame}
                  showDemoGame={showDemoGame}
                />}
              exact />
            <Route path="/terms-of-service" render={() => <TermsOfService logoutHandler={logoutHandler} />} exact />

            {/* Неизвестная страница */}
            <Route path="/raffle" render={() => <Raffle logoutHandler={logoutHandler} />} exact />

            {/* Временный роут */}
            <Route path="/dev" component={Dev} exact />
            <Redirect from="/" to="/home" exact />
            <Route path="/404" render={() => <Page404 logoutHandler={logoutHandler} />} />
            <Redirect to="/404" />
          </IonRouterOutlet>

        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;