import React, { useState } from 'react';
import { IonButton, IonModal, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalHistory, modalHistoryVerify } from '../state';
import closeModal from '../images/icons-other/close-modal.svg';
import ModalHistoryVerify from './ModalHistoryVerify';

const ModalHistory = () => {
  /*eslint-disable*/
  const [isOpenModal, setIsOpenModal] = useAtom(modalHistory);
  const [, setHistoryVerifyOpen] = useAtom(modalHistoryVerify);

  const [historyTab, setHistoryTab] = useState('bets');

  const [open, setOpen] = useState([]);
  const collapseDetails = (e, arr) => {
    let tempArr = arr;
    if (!tempArr.includes(e)) {
      setOpen([...arr, e]);
    } else {
      tempArr.splice(tempArr.indexOf(e), 1);
      setOpen([...tempArr]);
    };
  }
  /*eslint-enable*/
  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">History</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
          />
        </div>
        <div className="modal-content modal-history custom-scroll">
          <IonSegment
            scrollable
            className="modal-tabs"
            mode="md"
            value={historyTab}
            onIonChange={e => {
              setHistoryTab(e.detail.value);
              setOpen([])
            }}
          >
            <IonSegmentButton value="bets">
              <IonLabel>Bets</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="deposit">
              <IonLabel>Deposit</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="withdraw">
              <IonLabel>Withdraw</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="all">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-hl"></div>

          {historyTab === 'bets' &&
            <div className="modal-content-body">
              <table className="modal-table bets-table">
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <div className="table-btns-container flex">
                        <div className="btn-default-container">
                          <IonButton className="btn-default" type="submit">Previous</IonButton>
                        </div>
                        <div className="btn-container">
                          <IonButton className="btn-orange" type="submit">claim</IonButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  {[1, 1, 1, 1, 1, 1, 1, 1].map((item, i) =>
                    <React.Fragment key={i}>
                      <tr className="modal-row-odd">
                        <td>08/06 02:49:12</td>
                        <td>Mines</td>
                        <td className="negative-balance">-$0.95</td>
                        <td className="cell-details">
                          <span onClick={() => collapseDetails(i, open)}>Details</span>
                        </td>
                      </tr>
                      <tr className={`modal-row-even ${(open.indexOf(i) !== -1 && 'open')}`}>
                        <td colSpan="3">
                          <div><strong>Bet Id:</strong> 5f8265hyt0j6ft765b542lo</div>
                          <div><strong>Bet Amount:</strong> 0.95</div>
                        </td>
                        <td className="cell-verify">
                          <span onClick={() => setHistoryVerifyOpen(true)}>Verify Bet</span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          }

          {historyTab === 'deposit' &&
            <div className="modal-content-body">
              <table className="modal-table bets-table">
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <div className="table-btns-container flex">
                        <div className="btn-default-container">
                          <IonButton className="btn-default" type="submit">Previous</IonButton>
                        </div>
                        <div className="btn-container">
                          <IonButton disabled={true} className="btn-orange" type="submit">claim</IonButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  {[1, 1, 1, 1, 1, 1].map((item, i) =>
                    <React.Fragment key={i}>
                      <tr className="modal-row-odd">
                        <td>08/06 02:49:12</td>
                        <td>Bitcoin Deposit</td>
                        <td className="positive-balance">$0.95</td>
                        <td className="cell-details">
                          <span onClick={() => collapseDetails(i, open)}>Details</span>
                        </td>
                      </tr>
                      <tr className={`modal-row-even ${(open.indexOf(i) !== -1 && 'open')}`}>
                        <td colSpan="4">
                          <div><strong>Hash:</strong> 5soicfodujfdklkfcdpookvofjkvifgrfjgkopritg905804iorke48ufjiedfuviedcfieur893reowjidiu</div>
                          <div><strong>Amount:</strong> $1,218.97</div>
                          <div><strong>Timestamp:</strong> 2020-10-10T13:57:36-05:00</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          }

          {historyTab === 'withdraw' &&
            <div className="modal-content-body">
              <table className="modal-table bets-table">
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <div className="table-btns-container flex">
                        <div className="btn-default-container">
                          <IonButton className="btn-default" type="submit">Previous</IonButton>
                        </div>
                        <div className="btn-container">
                          <IonButton disabled={true} className="btn-orange" type="submit">claim</IonButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  {[1].map((item, i) =>
                    <React.Fragment key={i}>
                      <tr className="modal-row-odd">
                        <td>08/06 02:49:12</td>
                        <td>Bitcoin Deposit</td>
                        <td className="positive-balance">$0.95</td>
                        <td className="cell-details">
                          <span onClick={() => collapseDetails(i, open)}>Details</span>
                        </td>
                      </tr>
                      <tr className={`modal-row-even ${(open.indexOf(i) !== -1 && 'open')}`}>
                        <td colSpan="4">
                          <div><strong>Hash:</strong> 5soicfodujfdklkfcdpookvofjkvifgrfjgkopritg905804iorke48ufjiedfuviedcfieur893reowjidiu</div>
                          <div><strong>Amount:</strong> $1,218.97</div>
                          <div><strong>Timestamp:</strong> 2020-10-10T13:57:36-05:00</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          }

          {historyTab === 'all' &&
            <div className="modal-content-body">
              <table className="modal-table bets-table">
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <div className="table-btns-container flex">
                        <div className="btn-default-container">
                          <IonButton className="btn-default" type="submit">Previous</IonButton>
                        </div>
                        <div className="btn-container">
                          <IonButton disabled={true} className="btn-orange" type="submit">claim</IonButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  {[0.95, -100.00, 117.00, 117.00, 117.00, 117.00, 117.00, 117.00, 117.00].map((item, i) =>
                    <React.Fragment key={i}>
                      <tr className="modal-row-odd">
                        <td>08/06 02:49:12</td>
                        <td>Mines</td>
                        <td className={`${item < 0 ? 'negative-balance' : 'positive-balance'}`}>${item}</td>
                        <td className="cell-details">
                          <span onClick={() => collapseDetails(i, open)}>Details</span>
                        </td>
                      </tr>
                      <tr className={`modal-row-even ${(open.indexOf(i) !== -1 && 'open')}`}>
                        <td colSpan="4">
                          <div><strong>Hash:</strong> 5soicfodujfdklkfcdpookvofjkvifgrfjgkopritg905804iorke48ufjiedfuviedcfieur893reowjidiu</div>
                          <div><strong>Amount:</strong> $1,218.97</div>
                          <div><strong>Timestamp:</strong> 2020-10-10T13:57:36-05:00</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          }
        </div>
      </div>

      <ModalHistoryVerify />
    </IonModal>
  )
}

export default ModalHistory;
