import { atom } from 'jotai';

// tempLiveFeed
import bitcoin from './images/icons-round/bitcoin.svg';
import ethereum from './images/icons-round/ethereum.svg';


// Data
export const tokenValue = atom('');
// export const rememberMe = atom(false);
// export const isAuth = atom(false);
export const langValue = atom('GB');
export const userData = atom({})
export const countriesList = atom([]);
export const currenciesList = atom([]);
export const languagesList = atom([{ code: 'GB', eng_name: 'English' }]);
export const gamesList = atom([]);
export const tagsList = atom([]);

// Modals
export const modalSignUp = atom(false);
export const modalLogin = atom(false);
export const modalRestore = atom(false);
export const modalGalaxyWards = atom(false);
export const modalCashier = atom(false);
export const modalCashierOfferApp = atom(false);
export const modalCashierLootablyApp = atom(false);
export const modalHistory = atom(false);
export const modalHistoryVerify = atom(false);
export const modalPreference = atom(false);
export const modalAffiliate = atom(false);
export const modalError = atom(false);
export const accountMobile = atom(false);

// Modal Game
export const modalGame = atom(false);
export const openGameId = atom(null);
export const defGameUrl = atom('');
export const defHtml = atom('');

//Main
export const mobileMenuButton = atom('home');
export const loadingMain = atom(false);

// Notifications & Errors
export const notificationsMobile = atom(false);
export const toastValue = atom(false);
export const toastTextValue = atom('');
export const errorToastValue = atom(false);
export const errorToastTextValue = atom('');
export const requiredFieldErrorText = atom('The field is required');


export const tempLiveFeed = atom([
  {
    id: 'live1',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: bitcoin,
    mult: '0.00x',
    payout: '255.16'
  },
  {
    id: 'live2',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: ethereum,
    mult: '0.29x',
    payout: '-18.16'
  },
  {
    id: 'live3',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: ethereum,
    mult: '0.35x',
    payout: '3.16'
  },
  {
    id: 'live4',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: bitcoin,
    mult: '0.29x',
    payout: '532.16'
  },
  {
    id: 'live5',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: ethereum,
    mult: '0.00x',
    payout: '-1203.16'
  },
  {
    id: 'live6',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: ethereum,
    mult: '2.00x',
    payout: '5423.16'
  },
  {
    id: 'live7',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: bitcoin,
    mult: '0.29x',
    payout: '298.16'
  },
  {
    id: 'live8',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: ethereum,
    mult: '0.29x',
    payout: '33.16'
  },
  {
    id: 'live9',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: ethereum,
    mult: '0.29x',
    payout: '222.16'
  },
  {
    id: 'live10',
    game: 'Mines',
    user: 'Kiwi4life',
    time: '2:51 AM',
    wager: '$18.00',
    icon: bitcoin,
    mult: '0.29x',
    payout: '9.16'
  },
]);

export const tempNotificationData = atom([
  {
    time: '4 h. ago',
    text: "Leave the winter coat at home and pack the suntan lotion for an exotic safari trip by grabbing up to 120 free spins in three safari-themed slots on your next deposits.",
    isNew: true
  },
  {
    time: '5 h. ago',
    text: "Leave the winter coat at home and pack the suntan lotion for an exotic safari trip by grabbing up to 120 free spins in three safari-themed slots on your next deposits.",
    isNew: true
  },
  {
    time: '6 h. ago',
    text: "Leave the winter coat at home and pack the suntan lotion for an exotic safari trip by grabbing up to 120 free spins in three safari-themed slots on your next deposits.",
    isNew: true
  },
  {
    time: '7 h. ago',
    text: "Join today's race in the winter wilderness of Ice Joker. Work your way up the leaderboard and compete for €500 wager-free cash and 500 free spins.",
    isNew: false
  }
]);