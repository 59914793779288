import React from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonModal } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalHistoryVerify } from '../state';
import closeModal from '../images/icons-other/close-modal.svg';
import verifyResultImg from '../images/verify-result.png';

const ModalHistoryVerify = (props) => {
  const [isOpenModal, setIsOpenModal] = useAtom(modalHistoryVerify);

  const options = {
    cssClass: 'custom-select'
  }


  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal-small"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container">
        <div className="modal-history-verify-header flex">
          <div className="modal-history-verify-title">Verify Results</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
          />
        </div>

        <div className="modal-content-verify custom-scroll">
          <div className="modal-verify-title">Enter a Bet ID to verify the results of your game</div>

          <div className="modal-verify-bet">
            <div className="modal-verify-inputs">
              <div className="modal-verify-subtitle">Bet ID</div>
              <IonItem className="ion-floating-style">
                <IonLabel position="floating">Bet ID</IonLabel>
                <IonInput value="5f8265hyt0j6ft765b542lo">
                </IonInput>
              </IonItem>
              <IonItem className="ion-floating-style">
                <IonSelect interfaceOptions={options} placeholder="Mines" interface={'popover'} mode={'md'}>
                  <IonSelectOption value={'1'}>1</IonSelectOption>
                  <IonSelectOption value={'2'}>2</IonSelectOption>
                </IonSelect>
              </IonItem>
              <div className="btn-container">
                <IonButton className="btn-orange" type="submit">Verify</IonButton>
              </div>
            </div>
          </div>

          <div className="modal-verify-hash">
            <div className="modal-verify-inputs">
              <div className="modal-verify-subtitle">Hashed Server Seed</div>
              <IonItem className="ion-floating-style">
                <IonLabel position="floating">Hashed Server Seed</IonLabel>
                <IonInput value="h765thkjkhgggjk89u8776t7y6789ioktfyt6t7yo89u9iuokjhhtg67yiujh76y7uhnjrgfhttyu">
                </IonInput>
              </IonItem>
              <IonItem className="ion-floating-style">
                <IonLabel position="floating">Server Seed</IonLabel>
                <IonInput value="h765thkjkhgggjk89u8776t7y6789ioktfyt6t7yo89u9iuokjhhtg67yiujh76y7uhnjrgfhttyu">
                </IonInput>
              </IonItem>
              <IonItem className="ion-floating-style">
                <IonLabel position="floating">Client Seed</IonLabel>
                <IonInput value="uyhhjgyuyi87">
                </IonInput>
              </IonItem>
              <IonItem className="ion-floating-style">
                <IonLabel position="floating">Nonce</IonLabel>
                <IonInput value="29">
                </IonInput>
              </IonItem>
              <div className="btn-container">
                <IonButton className="btn-orange" type="submit">Verify</IonButton>
              </div>
            </div>
          </div>

          <div className="modal-verify-result">
            <div className="modal-verify-title">Result</div>
            <img src={verifyResultImg} alt="Result" />
          </div>

        </div>
      </div>
    </IonModal>
  )
}

export default ModalHistoryVerify;