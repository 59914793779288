import React from 'react';
import { useAtom } from 'jotai';
import { tempLiveFeed } from '../state';
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';

const LiveFeed = () => {
  /*eslint-disable*/
  const [liveFeed, setLiveFeed] = useAtom(tempLiveFeed);
  /*eslint-enable*/
  return (
    <div className="home-live-container">
      <div className="home-live-top">
        <div className="home-live-title">Live Feed</div>
        <div>
          <IonSegment
            scrollable
            mode="md"
            value="all"
            onIonChange={e => console.log('Segment selected', e.detail.value)}
          >
            <IonSegmentButton value="all">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="highWines">
              <IonLabel>High Wins</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="luckyWins">
              <IonLabel>Lucky Wins</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-hl live"></div>
        </div>
      </div>
      <div className="home-live-table-container">
        <table className="home-live-table">
          <thead>
            <tr>
              <th>Game</th>
              <th className="only-desktop">User</th>
              <th className="only-desktop">Time</th>
              <th className="only-desktop">Wager</th>
              <th className="only-desktop">Mult</th>
              <th>Payout</th>
            </tr>
          </thead>
          <tbody>
            {liveFeed.map(item =>
              <tr key={item.id}>
                <td className="game secondary">{item.game}</td>
                <td className="user only-desktop primary">{item.user}</td>
                <td className="only-desktop secondary">{item.time}</td>
                <td className="only-desktop secondary flex">
                  <p className="only-desktop">{item.wager}</p>
                  <img className="only-desktop" src={item.icon} alt="currency icon" />
                </td>
                <td className={`only-desktop ${item.mult === '0.00x' ? 'secondary' : 'green'}`}>{item.mult}</td>
                <td className={`${item.payout < 0 ? 'red' : 'green'} bold flex`}>
                  <p>${item.payout}</p>
                  <img src={item.icon} alt="currency icon" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LiveFeed;
