import React from 'react';
import { Link } from 'react-router-dom';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import closeModal from '../images/icons-other/close-modal.svg';
import dollarSmallIcon from '../images/icons-input/dollar-small.svg';
import clip from '../images/icons-other/clip.svg';
import galaxyDailyImg from '../images/galaxy-img/daily-img.png';
import galaxyBigDailyImg from '../images/galaxy-img/daily-big.png';
import { ResponsiveImage, ResponsiveImageSize } from 'react-responsive-image';



const Dev = () => {

  return (
    <>
      {/* Chat Rulers */}
      <div className="dev-modal-wrap">
        <div className="modal-container">
          <div className="modal-header modal-user-header">
            <div className="modal-title">
              <div>SlotGod</div>
              <div className="modal-user-join">Joined November 26, 2020</div>
            </div>
            <img
              className="modal-close-icon"
              src={closeModal}
              alt="close"
            />
          </div>

          <div className="modal-content custom-scroll">
            <div className="modal-content-body">
              <div className="modal-body-item">

                <div className="modal-chat-rulers-container">

                  <div className="modal-chat-rulers-header">
                    Roobet.com reserves the right to mute, or restrict chat abilities to any users based on moderator/staff discretion. Some actions that will result in restricted chat abilities:
                  </div>

                  <ul className="modal-chat-rulers-body">
                    <li>Chat spam</li>
                    <li>Asking or requesting any funds from any players</li>
                    <li>Advertising any external websites</li>
                    <li>Advertising promo codes</li>
                    <li>Racism, harmful speech, or attacking any players</li>
                    <li>Slander</li>
                  </ul>

                  <div className="modal-chat-rulers-footer">Happy Chatting!</div>

                </div>

                <div className="modal-chat-rulers-action flex">
                  <Link to="/" className="modal-action">Dismiss</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chat Modal */}
      <div className="dev-modal-wrap">
        <div className="modal-container">
          <div className="modal-header modal-user-header">
            <div className="modal-title">
              <div>SlotGod</div>
              <div className="modal-user-join">Joined November 26, 2020</div>
            </div>
            <img
              className="modal-close-icon"
              src={closeModal}
              alt="close"
            />
          </div>

          <div className="modal-content custom-scroll">
            <div className="modal-content-body">
              <div className="modal-body-item">

                <div className="modal-user-container">
                  <div className="modal-user-item-header flex">
                    <div className="modal-user-wagers-list flex">

                      {/* Modal-user-wagers Filled */}
                      <div className="modal-user-wagers-item before flex">
                        <div className="modal-user-wagers-block">
                          <div className="modal-user-wagers-value">$105,70</div>
                          <div className="modal-user-wagers-label">Wagered</div>
                        </div>
                      </div>
                      <div className="modal-user-wagers-item before">
                        <div className="modal-user-wagers-block">
                          <div className="modal-user-wagers-value">998</div>
                          <div className="modal-user-wagers-label">Wagers</div>
                        </div>
                      </div>
                      {/* Modal-user-wagers Empty */}
                      {/* <div className="modal-user-wagers-item modal-user-wagers-noflex before flex">
                        <div className="modal-user-wagers-block">
                          <div className="modal-user-wagers-value">$105,70</div>
                          <div className="modal-user-wagers-label">Wagered</div>
                        </div>
                      </div>
                      <div className="modal-user-wagers-item modal-user-wagers-noflex before">
                        <div className="modal-user-wagers-block">
                          <div className="modal-user-wagers-value">998</div>
                          <div className="modal-user-wagers-label">Wagers</div>
                        </div>
                      </div> */}

                    </div>
                    <div className="modal-user-tip flex">
                      <div className="btn-default-container">
                        <IonButton className="btn-tip" type="submit">
                          <IonIcon icon={clip}></IonIcon>
                        </IonButton>
                      </div>
                      <div className="btn-default-container">
                        <IonButton className="btn-tip" type="submit">Send Tip</IonButton>
                      </div>
                    </div>
                  </div>
                  <div className="modal-user-item-footer flex">
                    <div className="btn-container">
                      <IonButton className="btn-orange" type="submit">Chat mute</IonButton>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Сashier */}
      <div className="dev-modal-wrap">
        <div className="modal-container">
          <div className="modal-header">
            <div className="modal-title">Сashier</div>
            <img
              className="modal-close-icon"
              src={closeModal}
              alt="close"
            />
          </div>

          <div className="modal-content custom-scroll">
            <div className="modal-content-body">
              <div className="modal-notification-item before flex">
                <div className="modal-notification-text">
                  Your balance remaining after rain must be $10 or greater. You must have deposited
                  & all deposits must have at least 3 confirmations.</div>
                <div className="modal-action-container">
                  <Link to="/" className="modal-action open-action before">Open RooWards</Link>
                </div>
              </div>
              <div className="modal-body-item">

                <div className="modal-cashier-form-container">
                  <div className="modal-cashier-inputs flex">
                    <div className="input-cashier-container">
                      <IonItem lines="none" className="ion-floating2-style with-notification">
                        <IonLabel position="floating">Amount</IonLabel>
                        <IonInput
                          type="number"
                          value=""
                          onIonChange={e => console.log(e.detail.value)}
                        ></IonInput>
                        <IonIcon
                          slot="end"
                          icon={dollarSmallIcon}
                        ></IonIcon>
                      </IonItem>
                      <div className="input-notification">Minimum withdrawal amount is $10.00</div>
                    </div>
                    <div className="input-cashier-container">
                      <IonItem lines="none" className="ion-floating2-style with-notification">
                        <IonLabel position="floating">Wallet</IonLabel>
                        <IonInput
                          type="number"
                          value=""
                          onIonChange={e => console.log(e.detail.value)}
                        ></IonInput>
                        <IonIcon
                          slot="end"
                          icon={dollarSmallIcon}
                        ></IonIcon>
                      </IonItem>
                      <div className="input-notification">$0.00 Availale</div>
                    </div>
                  </div>

                  <div className="modal-cashier-bottom flex">
                    <div className="btn-default-container">
                      <IonButton className="btn-default" type="submit">Cancel</IonButton>
                    </div>
                    <div className="btn-container">
                      <IonButton className="btn-orange" type="submit">Start</IonButton>
                    </div>
                    <div className="modal-notification before">
                      <div>At least 10 referrals must have deposited.</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Daily Rakeback Galaxy Claimed */}
      <div className="dev-modal-claim">
        <div className="modal-claim">
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
          />
          <ResponsiveImage className="modal-claim-image">
            <ResponsiveImageSize
              default
              minWidth={0}
              path={galaxyDailyImg}
              className="modal-claim-image"
            />
            <ResponsiveImageSize
              minWidth={1921}
              path={galaxyBigDailyImg}
            />
          </ResponsiveImage>
          <p className="modal-claim-title">Daily Rakeback</p>
          <p className="modal-claim-subtitle">Galaxy claimed</p>
          <div className="modal-claim-money before flex">
            <span>$12,00</span>
          </div>
        </div>
      </div>

    </>
  )
}

export default Dev;
