import React from 'react';
import { Link } from 'react-router-dom';
import { IonButton, IonModal, IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalAffiliate } from '../state';
import closeModal from '../images/icons-other/close-modal.svg';
import { copyText } from '../utils/functions.js';
import Graph from './Graph.js'

const ModalAffiliate = () => {
  const [isOpenModal, setIsOpenModal] = useAtom(modalAffiliate);

  const options = {
    cssClass: 'custom-select'
  }

  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">Affiliate Panel</div>
          <img
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
            className="modal-close-icon"
          />
        </div>
        <div className="modal-content custom-scroll">
          <div className="modal-contrnt-body">
            <div className="modal-body-item">

              <div className="affiliate-referral">
                <div className="affiliate-referral-top flex">
                  <div className="affiliate-referral-left">
                    <div className="affiliate-referral-title">Your Referral Link</div>
                    <p onClick={(e) => { e.preventDefault(); copyText('text') }} className="affiliate-referral-address">https://galaxy.ly/f0ffd4g</p>
                  </div>
                  <div className="affiliate-referral-right">
                    <p onClick={() => copyText('text')} className="modal-action copy-action before flex">Copy URL</p>
                  </div>
                </div>
                <div className="affiliate-referral-bottom">
                  Share the link above or have friends use your promo code "ula". They'll be rewarded with a free RooWards boost to level 1 - and you will receive commission on all wagers, for life. <Link to="/">Marketing Assets</Link>
                </div>
              </div>

              <div className="affiliate-commission-list">
                <div className="affiliate-commission-item before flex">
                  <div className="affiliate-balance">
                    <div className="affiliate-balance-label">Available Earnings</div>
                    <div className="affiliate-balance-value">$0.00</div>
                  </div>
                  <IonButton className="btn-default" type="submit">Claim</IonButton>
                  <div className="modal-notification before">
                    <div>At least 10 referrals must have deposited.</div>
                  </div>
                </div>

                <div className="affiliate-commission-item before flex">
                  <div className="affiliate-balance">
                    <div className="affiliate-balance-label">Refferals</div>
                    <div className="affiliate-balance-value">0</div>
                  </div>
                </div>

                <div className="affiliate-commission-item before flex">
                  <div className="affiliate-balance">
                    <div className="affiliate-balance-label">Depositors</div>
                    <div className="affiliate-balance-value">0</div>
                  </div>
                </div>

                <div className="affiliate-commission-item before flex">
                  <div className="affiliate-balance">
                    <div className="affiliate-balance-label">Deposits</div>
                    <div className="affiliate-balance-value">0</div>
                  </div>
                </div>
              </div>

              <div className="commission-indicator-container">
                <div className="commission-indicator-line before"></div>
                <div className="commission-indicator-diapason flex">
                  <div>Tier 1-5% Commision</div>
                  <div>Tier 2-6% Commision</div>
                </div>
              </div>

              <div className="schedule-content">
                <div className="schedule-header flex">
                  <div className="schedule-title">Refferal Earning</div>
                  <IonItem className="schedule-date" lines="none">
                    <IonSelect interfaceOptions={options} value={'7 days'} placeholder="Select" interface={'popover'}>
                      {/* <IonIcon slot="end" icon={calendarIcon}></IonIcon> */}
                      <IonSelectOption value={'7 days'}>7 days</IonSelectOption>
                      <IonSelectOption value={'14 days'}>14 days</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <Link to="/" className="modal-action refresh-action before">Refresh</Link>
                </div>
                <Graph />
              </div>

              <table className="affiliate-games-table">
                <thead>
                  <tr>
                    <td>Reffera</td>
                    <td>Commisions Generated</td>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td colSpan="2">
                      <div className="affiliate-games-footer flex">
                        <div className="affiliate-games-sort-container flex">
                          <div className="affiliate-games-sort-label">Rows per page:</div>
                          <IonItem className="affiliate-games-sort-value" lines="none">
                            <IonSelect interfaceOptions={options} value={'5'} placeholder="Select" interface={'popover'}>
                              {/* <IonIcon slot="end" icon={calendarIcon}></IonIcon> */}
                              <IonSelectOption value={'5'}>5</IonSelectOption>
                              <IonSelectOption value={'10'}>10</IonSelectOption>
                            </IonSelect>
                          </IonItem>
                        </div>

                        <div className="affiliate-games-pages-container">
                          <span className="affiliate-games-pages-diapason">1-3</span>
                          <span>&nbsp;of&nbsp;</span>
                          <span className="affiliate-games-pages-entire">3</span>
                        </div>
                        <div className="affiliate-games-buttons flex">
                          <div className="affiliate-games-button-prev"></div>
                          <div className="affiliate-games-button-next"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr>
                    <td>kickindexie</td>
                    <td>$0,31</td>
                  </tr>
                  <tr>
                    <td>CSG</td>
                    <td>$0,5</td>
                  </tr>
                  <tr>
                    <td>Roachtime</td>
                    <td>$0,11</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </IonModal>
  )
}

export default ModalAffiliate;
