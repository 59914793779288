import {
  IonButton, IonIcon, IonInput, IonItem,
  IonLabel, IonModal
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {
  modalRestore, errorToastValue, errorToastTextValue,
  toastValue, toastTextValue
} from '../state';
import * as API from '../api/api.js';
import closeModal from '../images/icons-other/close-modal.svg';
import userIcon from '../images/icons-header/account.svg';
import validIcon from '../images/icons-input/validate.svg';
import passIconClose from '../images/icons-input/pass-invisible.svg';
import passIconOpen from '../images/icons-input/pass-visible.svg';

const ModalRestore = ({ checkField, checkRepasswordField }) => {
  const [isOpen, setIsOpen] = useAtom(modalRestore);
  const [step, setStep] = useState(1);
  const [, setErrorToast] = useAtom(errorToastValue);
  const [, setErrorToastText] = useAtom(errorToastTextValue);
  const [, setSuccessToast] = useAtom(toastValue);
  const [, setSuccessToastText] = useAtom(toastTextValue);

  // Step 1
  const [userName, setUserName] = useState('');
  const [userNameSuccess, setUserNameSuccess] = useState();
  const [userNameErrorText, setUserNameErrorText] = useState('');

  // Step 2
  const [vcode, setVcode] = useState('');
  const [vcodeSuccess, setVcodeSuccess] = useState();
  const [vcodeErrorText, setVcodeErrorText] = useState('');
  const [password, setPassword] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState();
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [repassword, setRepassword] = useState('');
  const [repasswordSuccess, setRepasswordSuccess] = useState();
  const [repasswordErrorText, setRepasswordErrorText] = useState('');
  const [passVisible, setPassVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);



  // Check button

  useEffect(() => {
    const checkButtonDisabled = () => {
      if (userName && vcode && password && repassword) {
        setButtonDisabled(false);
      }
    };
    checkButtonDisabled();
  }, [userName, vcode, password, repassword])



  // Check on steps

  function restorePassword() {
    if (userName) {
      API.forgot(userName)
        .then(res => {
          if (res.data.status === 1) {
            setUserNameSuccess(true);
            setStep(2);
          }
          if (res.data.error) {
            setUserNameSuccess(false);
            setUserNameErrorText(res.data.error);
          };
        })
        .catch(error => {
          setUserNameSuccess(false);
          setUserNameErrorText(error.response.data.error);
          setErrorToastText(error.response.data.error);
          setErrorToast(true);
        })
    } else {
      checkUserName();
    }
  }

  function restoreFinal() {
    if (userNameSuccess && passwordSuccess && repasswordSuccess) {
      API.forgotVerification(userName, vcode, password)
        .then(res => {
          if (res.data.status === 1) {
            setUserNameSuccess(true);
            setVcodeSuccess(true);
            setPasswordSuccess(true);
            setRepasswordSuccess(true);
            setIsOpen(false);
            setSuccessToastText('Your password was succesfully changed');
            setSuccessToast(true);
            setTimeout(() => window.location.reload(), 3000)
          } else if (res.data.error === "User not found") {
            setUserNameSuccess(false);
            setUserNameErrorText(res.data.error);
          } else if (res.data.errors === "Code is not correct") {
            setVcodeSuccess(false);
            setVcodeErrorText(res.data.errors);
          } else if (res.data.error) {
            setErrorToastText(res.data.error);
            setErrorToast(true);
          } else if (res.data.errors) {
            setErrorToastText(res.data.errors);
            setErrorToast(true);
          }
        })
        .catch(error => {
          // 422
          setErrorToastText(error.response.data.error);
          setErrorToast(true);
        })
    } else {
      checkUserName();
      checkPassword();
      checkRepassword();
    }
  }



  // Check fields

  function checkUserName() {
    checkField(userName, setUserNameSuccess, setUserNameErrorText);
  }

  function checkVcode() {
    checkField(vcode, setVcodeSuccess, setVcodeErrorText);
  }

  function checkPassword() {
    checkField(password, setPasswordSuccess, setPasswordErrorText);
  }

  function checkRepassword() {
    checkRepasswordField(
      password, repassword,
      setPasswordSuccess, setRepasswordSuccess,
      setPasswordErrorText, setRepasswordErrorText);
  }



  return (
    <IonModal
      isOpen={isOpen}
      cssClass="modal"
      onDidDismiss={() => setIsOpen(false)}
      className="restore-modal"
    >
      <div className="modal-auth-container">

        <div className="modal-header">
          <div className="modal-title">Log In</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div className="modal-login-content custom-scroll">
          <div className="modal-login-left">
            <div className="modal-login-head">
              Welcome back to Twin
            </div>
            <div className="modal-login-text">
              A gift for the restoration of the same mayzhe tviy! Memorize the form and cut off 200 free rounds in 10 days, a 200% bonus on your first deposit and a non-cash sports bet!
            </div>
          </div>
          <div className="modal-login-right">
            <div className="modal-login-top-subtitle">Login failed</div>
            <div className="modal-login-subtitles">
              <div className="modal-login-subtitle">Forgot password</div>
              {step === 1 && <>
                <div className="modal-login-description">
                  Enter your email, phone, or username and we'll send you a link to get back into your account.
            </div>
              </>}
            </div>

            {step === 1 && <>
              <form
                className="modal-login-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  restorePassword();
                }}
              >
                <div className="item-input-wrapper">
                  <IonItem className={'ion-floating-style' + (userNameSuccess === false ? ' error-input' : '')}>
                    <IonLabel position="floating">Email, phone, or username</IonLabel>
                    <IonInput
                      value={userName}
                      onIonChange={e => setUserName(e.detail.value)}
                    ></IonInput>
                    <IonIcon slot="end" icon={userNameSuccess ? validIcon : userIcon}></IonIcon>
                  </IonItem>
                  {userNameSuccess === false && <div className="error-block">{userNameErrorText}</div>}
                </div>
                <div className="btn-container">
                  <IonButton className="btn-orange" type="submit">Resent password</IonButton>
                </div>
              </form>
            </>
            }



            {step === 2 && <>
              <form
                className="modal-login-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  restoreFinal();
                }}
              >
                <div className="item-input-wrapper">
                  <IonItem
                    className={'ion-floating-style' + (userNameSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkUserName()}
                  >
                    <IonLabel position="floating">Email, phone, or username</IonLabel>
                    <IonInput value={userName} onIonChange={e => setUserName(e.detail.value)} disabled={true}></IonInput>
                    <IonIcon slot="end" icon={userNameSuccess ? validIcon : userIcon}></IonIcon>
                  </IonItem>
                  {userNameSuccess === false && <div className="error-block">{userNameErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem className={'ion-floating-style' + (vcodeSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkVcode()}
                  >
                    <IonLabel position="floating">Verification code from e-mail</IonLabel>
                    <IonInput value={vcode} onIonChange={e => setVcode(e.detail.value)}></IonInput>
                    <IonIcon slot="end" icon={vcodeSuccess ? validIcon : userIcon}></IonIcon>
                  </IonItem>
                  {vcodeSuccess === false && <div className="error-block">{vcodeErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem className={'ion-floating-style' + (passwordSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkPassword()}
                  >
                    <IonLabel position="floating">New password</IonLabel>
                    <IonInput
                      value={password}
                      onIonChange={e => setPassword(e.detail.value)}
                      type={passVisible ? 'text' : 'password'}
                    ></IonInput>
                    <IonIcon
                      className="pass-icon"
                      slot="end"
                      icon={(passwordSuccess && validIcon) || (passVisible ? passIconClose : passIconOpen)}
                      onClick={() => setPassVisible(!passVisible)}
                    ></IonIcon>
                  </IonItem>
                  {passwordSuccess === false && <div className="error-block">{passwordErrorText}</div>}
                </div>

                <div className="item-input-wrapper">
                  <IonItem className={'ion-floating-style' + (repasswordSuccess === false ? ' error-input' : '')}
                    onIonBlur={() => checkRepassword()}
                  >
                    <IonLabel position="floating">Repeat new password</IonLabel>
                    <IonInput
                      value={repassword}
                      onIonChange={e => setRepassword(e.detail.value)}
                      type={passVisible ? 'text' : 'password'}
                    ></IonInput>
                    <IonIcon
                      className="pass-icon"
                      slot="end"
                      icon={(repasswordSuccess && validIcon) || (passVisible ? passIconClose : passIconOpen)}
                      onClick={() => setPassVisible(!passVisible)}
                    ></IonIcon>
                  </IonItem>
                  {repasswordSuccess === false && <div className="error-block">{repasswordErrorText}</div>}
                </div>

                <div className="btn-container">
                  <IonButton className="btn-orange" type="submit" disabled={buttonDisabled}>Send</IonButton>
                </div>

              </form>
            </>}
          </div>
        </div>

      </div>
    </IonModal>
  )
}

export default ModalRestore;
