import React, { useState } from 'react';
import { IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalCashierOfferApp } from '../../state';
import closeModal from '../../images/icons-other/close-modal.svg';
import emailIcon from '../../images/icons-input/mail.svg';

const ModalCashierOfferApp = (props) => {
  // console.log(props.app);
  const [isOpenModal, setIsOpenModal] = useAtom(modalCashierOfferApp);

  const [cashierOfferTab, setCashierOfferTab] = useState('qr');

  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal-small"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container-small">
        <div className="modal-small-header flex">
          <div className="modal-app-logo-container flex">
            <img
              className="modal-app-logo"
              src={props.app && props.app.logo}
              alt="app logo"
            />
          </div>
          <div className="modal-title">{props.app && props.app.title}</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
          />
        </div>
        <div className="modal-small-body custom-scroll">
          <div className="modal-small-body-top flex">
            <div className="modal-small-body-top-label">Send link to your iPhone device</div>
            <IonButton className="btn-orange">
              {props.app && props.app.offerPoints} points
            </IonButton>
          </div>
          <IonSegment
            scrollable
            className="modal-tabs"
            mode="md"
            value={cashierOfferTab}
            onIonChange={e => setCashierOfferTab(e.detail.value)}
          >
            <IonSegmentButton value="qr">
              <IonLabel>Scan QR code</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="email">
              <IonLabel>E-mail to your phone</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-hl"></div>

          {cashierOfferTab === 'qr' &&
            <div className="modal-small-tab-content modal-qr-block">
              <div className="modal-qr-block-inner flex">
                <div className="modal-small-tab-content-info-top">Please use your iPhone device to scan the generated image code below</div>
                <img className="qr" src={props.app && props.app.qr} alt="qr icon" />
                <div className="modal-small-tab-content-right">
                  <div className="modal-small-tab-content-info-text">Please use your iPhone device to scan the generated image code below</div>
                  <div className="modal-small-tab-content-main-text">General Instructions</div>
                  <ul className="modal-small-tab-content-main-instructions-text">
                    <li>Scan the QR code with your smartphone or tablet</li>
                    <li>Install and begin trial</li>
                  </ul>
                </div>
              </div>
            </div>
          }

          {cashierOfferTab === 'email' &&
            <div className="modal-small-tab-content flex">
              <div className="modal-small-tab-content-left">
                <div className="modal-small-tab-content-main-text">General Instructions</div>
                <ul className="modal-small-tab-content-main-instructions-text">
                  <li>Enter your email to receive the offer link to your phone</li>
                  <li>Click the link from your smartphone or tablet</li>
                  <li>Install and begin trial</li>
                </ul>
              </div>
              <div className="modal-small-tab-content-right">
                <form
                  className="modal-small-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <IonItem
                    lines="none"
                    className="ion-floating2-style"
                  >
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      value=""
                      onIonChange={e => console.log(e.detail.value)}
                    ></IonInput>
                    <IonIcon slot="end" icon={emailIcon}></IonIcon>
                  </IonItem>
                  <IonItem
                    lines="none"
                    className="ion-floating2-style"
                  >
                    <IonLabel position="floating">Email again</IonLabel>
                    <IonInput
                      value=""
                      onIonChange={e => console.log(e.detail.value)}
                    ></IonInput>
                    <IonIcon slot="end" icon={emailIcon}></IonIcon>
                  </IonItem>
                  <div className="modal-small-form-btn-container btn-container">
                    <IonButton
                      className="btn-orange"
                      type="submit"
                    >
                      Continue
                    </IonButton>
                  </div>
                  <IonItem lines="none" className="checkbox-item">
                    <IonCheckbox
                      checked="true"
                      onIonChange={e => {
                        // setChecked(e.detail.checked);
                        console.log(e.detail.checked)
                      }}
                    >
                    </IonCheckbox>
                    <IonLabel>
                      By providing your email address and checking this box, you agree that the info will be used by OfferToro in order to provide you an improved service
                    </IonLabel>
                  </IonItem>
                </form>
              </div>
            </div>
          }
        </div>
      </div>
    </IonModal>
  )
}

export default ModalCashierOfferApp;
