import React, { useEffect, useState, memo } from "react";
import { IonModal } from "@ionic/react";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet";
import { useAtom } from "jotai";
import {
  openGameId, defGameUrl, defHtml, modalGame,
  loadingMain
} from "../state.js";
import closeModal from '../images/icons-other/close-modal.svg';


const ModalGame = memo(() => {
  const [isModalOpen, setIsModalOpen] = useAtom(modalGame);
  const [isPresented, setIspresented] = useState(false);
  const [gameUrl, setGameUrl] = useAtom(defGameUrl);
  const [html, setHtml] = useAtom(defHtml);
  const [, setOpenGame] = useAtom(openGameId);
  const [, setShowLoading] = useAtom(loadingMain);


  useEffect(() => {
    if (isModalOpen) {
      document.getElementsByTagName("body")[0].classList.add("hide-chat");
    }
  }, [isModalOpen]);


  function closeGame() {
    document.getElementsByTagName("body")[0].classList.remove("hide-chat");
    setIsModalOpen(false);
    setIspresented(false);
    setOpenGame(null);
    setGameUrl("");
    setHtml("");
  }



  return (
    <IonModal
      isOpen={isModalOpen}
      cssClass="game-modal"
      onDidDismiss={() => closeGame()}
      onDidPresent={() => setIspresented(true)}
    >
      <img
        className="close-game"
        src={closeModal}
        alt="close"
        onClick={() => closeGame(false)}
      />

      {gameUrl === 'false entry' && (
        <div className='err-window'>
          <p>
            {'This game is not available in your country.'}
          </p>
        </div>
      )}

      {gameUrl && gameUrl !== 'false entry' && (
        <Iframe
          onLoad={() => setTimeout(setShowLoading(false), 2000)}
          url={gameUrl.toString()}
          width="100%"
          height="100%"
          id="gameOpened"
          className="game-opened"
          display="initial"
          position="relative"
        />
      )}

      {html && isPresented && (
        <div className="html-frame">
          <div
            className="html-frame"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Helmet
            onLoad={() => setTimeout(setShowLoading(false), 2000)}
          >
            {html.match(/<script\b[^>]*>([\s\S]*?)<\/script>/gm) !== null &&
              Array.from(html.match(/<script\b[^>]*>([\s\S]*?)<\/script>/gm)).map((el, i) => {
                if (html.match('egamingsStartNetEnt') !== null) {
                  if (/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1] !== '') {
                    return (
                      <script type="text/javascript" key={i}>
                        {/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1] + ' egamingsStartNetEnt()'}
                      </script>
                    )
                  } if (/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1] === '') {
                    return (
                      <script key='added' type="text/javascript" src="https://egamings-static-test.casinomodule.com/gameinclusion/library/gameinclusion.js"></script>
                    )
                  }
                }
                else {
                  return (
                    <script type="text/javascript">
                      {/<script\b[^>]*>([\s\S]*?)<\/script>/gm.exec(el)[1]}
                    </script>
                  )
                }
                return null;
              }
              )
            }
          </Helmet>
        </div>
      )}
    </IonModal>
  );
});
export default ModalGame;
