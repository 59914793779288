import React from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonToggle } from '@ionic/react';
import bitcoinSmallIcon from '../../images/icons-round/bitcoin-small.svg';
import dollarSmallIcon from '../../images/icons-input/dollar-small.svg';

const TabTip = () => {
  return (
    <div className="modal-content-body">
      <div className="modal-body-item">
        <form className="modal-cashier-form" onSubmit={(e) => e.preventDefault()}>
          <div className="modal-cashier-form-inputs modal-cashier-form-notification">
            <IonItem
              className="ion-floating2-style"
              lines="none"
            >
              <IonLabel position="floating">Username</IonLabel>
              <IonInput
                value=""
                onIonChange={e => console.log(e.detail.value)}
              ></IonInput>
              <IonIcon slot="end" icon={bitcoinSmallIcon}></IonIcon>
            </IonItem>
            <div className="input-container">
              <IonItem
                className="ion-floating2-style with-notification"
                lines="none"
              >
                <IonLabel position="floating">Amount</IonLabel>
                <IonInput
                  type="number"
                  value=""
                  onIonChange={e => console.log(e.detail.value)}
                ></IonInput>
                <IonIcon
                  slot="end"
                  icon={dollarSmallIcon}
                ></IonIcon>
              </IonItem>
              <div className="input-notification">Minimum withdrawal amount is $10.00</div>
            </div>
            <IonItem
              className="ion-floating2-style"
              lines="none"
            >
              <IonSelect
                interface="popover"
                value=""
                onIonChange={e => console.log(e.detail.value)}
                placeholder="Bitcoin"
              >
                <IonSelectOption value="first">1</IonSelectOption>
                <IonSelectOption value="second">2</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
          <div className="modal-cashier-form-bottom">
            <div className="tip-form-bottom-left">
              <IonItem lines="none">
                <IonToggle
                  mode="ios"
                  // checked={checked}
                  onIonChange={e => console.log(e.detail.checked)}
                />
                <IonLabel>Display Amount Wagered</IonLabel>
              </IonItem>
            </div>
            <div className="form-cashier-btn-container btn-container">
              <IonButton
                className="btn-orange"
                type="submit"
              >
                Send tip
              </IonButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TabTip;
