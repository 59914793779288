import React from 'react';
import { IonButton } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalCashierOfferApp } from '../../state';

const SingleCardFreeOffer = (props) => {
  const [, setCashierOfferAppOpen] = useAtom(modalCashierOfferApp);

  return (
    <div className="cashier-free-app-container flex">
      <div className="cashier-free-app-left flex">
        <div className="cashier-free-app-logo-container flex">
          <img src={props.app.logo} alt="app logo" className="cashier-free-app-logo" />
        </div>
        <div className="cashier-free-app-info">
          <div className="cashier-free-app-info-title">{props.app.title}</div>
          <div className="cashier-free-app-info-description">{props.app.description}</div>
        </div>
      </div>
      <div className="form-cashier-btn-container btn-container">
        <IonButton
          className="btn-orange"
          onClick={() => {
            props.setIdxFreeApp(props.i);
            setCashierOfferAppOpen(true);
          }}
        >
          {props.app.offerPoints} points
        </IonButton>
      </div>
    </div>
  )
}

export default SingleCardFreeOffer;
