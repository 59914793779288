import React, { useState } from 'react';
import { copyText } from '../../utils/functions.js';
import refreshIcon from '../../images/icons-action/refresh.svg';
import doneIcon from '../../images/icons-action/done.svg';
import copyIcon from '../../images/icons-action/copy.svg';
import arrow from '../../images/icons-input/select-dropdown.svg';
import qrIcon from '../../images/qr.png';

const SingleCardDeposit = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = useState([]);
  const collapseDescr = (e, arr) => {
    let tempArr = arr;
    if (!tempArr.includes(e)) {
      setOpen([...arr, e]);
    } else {
      tempArr.splice(tempArr.indexOf(e), 1);
      setOpen([...tempArr]);
    };
  }

  return (
    <div className={`modal-body-item ${(open.indexOf(props.i) !== -1 && 'open')}`} key={props.i}>
      <div
        className="modal-body-item-header"
        onClick={() => collapseDescr(props.i, open)}
      >
        <img className="icon" src={props.item.icon} alt={`${props.item.currency} icon`} />
        <div className="modal-body-item-heading">{props.item.currency}</div>
        <div className="modal-body-item-arrow-container">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <div className="modal-body-item-collapse">
        <div className="modal-body-item-description">
          <img className="qr" src={qrIcon} alt="qr icon" />
          <div className="modal-body-item-description-right">
            <div className="modal-body-item-description-top-text">Your {props.item.currency} Address</div>
            <div className="modal-body-item-address">1Yhkl765Khyds4klW864Lgkv169L06bGh85</div>
            <div className="modal-body-item-description-text">
              Send any amount of BTC to the following address. In the case of a non-instant deposit, 1 confirmationis required.
            </div>
          </div>
        </div>
        <div className="modal-body-item-footer flex">
          {isCopied &&
            <div className="modal-body-item-footer-element">
              <img src={refreshIcon} alt="refresh icon" />
              <div className="modal-body-item-footer-text">Request New Address</div>
            </div>
          }
          <div className="modal-body-item-footer-element" onClick={() => { copyText('text'); setIsCopied(true) }}>
            <img src={isCopied ? doneIcon : copyIcon} alt="copy icon" />
            <div className="modal-body-item-footer-text">{`${isCopied ? 'Copied' : `Copy ${props.item.currency} address`}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleCardDeposit;
