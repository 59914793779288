import React, { useState } from 'react';
import { IonInput, IonItem, IonLabel, IonSegment, IonSegmentButton, IonSelect, IonSelectOption } from '@ionic/react';
import { Link } from 'react-router-dom';
import ModalCashierOfferApp from './ModalCashierOfferApp';
import ModalCashierLootablyApp from './ModalCashierLootablyApp';
import SingleCardFreeOffer from './SingleCardFreeOffer';
import SingleCardFreeLootably from './SingleCardFreeLootably';
import arrow from '../../images/icons-input/select-dropdown.svg';
import qrIcon from '../../images/qr.png';
import importantIcon from '../../images/icons-oval/important.svg';
import lootablyIcon from '../../images/icons-app/lootably.png';
import settingsIcon from '../../images/icons-other/settings.svg';

// TempCashierFreeAppsLogo
import streamItLogo from '../../images/icons-app/stream-it-app.png';
import presentLogo from '../../images/icons-app/present-app.png';
import swagbucksLogo from '../../images/icons-app/swagbucks-app.png';

const TabFree = () => {
  const [cashierFreeTab, setCashierFreeTab] = useState('offertoro');

  // TempCashierFreeApps
  const [cashierFreeApp,] = useState([
    {
      id: 1,
      logo: streamItLogo,
      title: 'Stream It (Chrome Extension)',
      description: 'Rewards after you install, launch the chrome extension and do some searches within 24 hours.',
      qr: qrIcon,
      offerPoints: 18,
      lootablyPoints: 0.95,
    },
    {
      id: 2,
      logo: presentLogo,
      title: 'Present - Guided Meditation - iPhone',
      description: 'Install and begin trial',
      qr: qrIcon,
      offerPoints: 15,
      lootablyPoints: 0.68,
      devices: ['IPhone', 'IPad'],
    },
    {
      id: 3,
      logo: swagbucksLogo,
      title: 'Swagbucks - 500 SB',
      description: 'Swagbucks is a loyalty program where users can earn rewards. Rewards after you register, confirm your account and earn 500 or more SwagBucks. This offer can only be completed by new Swagbucks users.',
      qr: qrIcon,
      offerPoints: 1620,
      lootablyPoints: 3,
      devices: ['Android'],
    },
  ])

  const [idxFreeApp, setIdxFreeApp] = useState(null);

  const [open, setOpen] = useState([]);

  const collapseDescr = (e, arr) => {
    let tempArr = arr;
    if (!tempArr.includes(e)) {
      setOpen([...arr, e]);
    } else {
      tempArr.splice(tempArr.indexOf(e), 1);
      setOpen([...tempArr]);
    };
  }

  return (
    <div className="modal-content-body">
      <div className={`modal-body-item ${(open.indexOf(0) !== -1 && 'open')}`}>
        <div
          className="modal-body-item-header"
          onClick={() => collapseDescr(0, open)}
        >
          <div className="modal-body-item-heading flex">
            <span>Serveys</span>
            <span className="modal-body-item-header-text">Add funds and play for free</span>
          </div>
          <div className="modal-body-item-arrow-container">
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="modal-body-item-collapse">
          <div className="modal-body-item dark-blue-container">
            <div className="modal-body-item-description">
              <img className="icon" src={importantIcon} alt="important icon" />
              <div className="modal-body-item-description-label">
                Please disable and refrain from using a VPN when completing surveys
              </div>
            </div>
          </div>
          <IonSegment
            className="modal-tabs"
            mode="md"
            value={cashierFreeTab}
            onIonChange={e => setCashierFreeTab(e.detail.value)}
          >
            <IonSegmentButton className="modal-tabs-cashier-free-button" value="offertoro">
              <IonLabel>Offertoro</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="modal-tabs-cashier-free-button" value="lootably">
              <IonLabel>Lootably</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-cashier-free-hl"></div>

          {cashierFreeTab === 'offertoro' &&
            <div className="offertoro-container">
              <div className="cashier-free-sort-container flex">
                <IonSegment
                  scrollable
                  mode="ios"
                  value="all"
                  onIonChange={e => console.log('Segment selected', e.detail.value)}
                >
                  <IonSegmentButton value="all">
                    <IonLabel>All offers</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="mobile">
                    <IonLabel>Mobile apps</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="desktop">
                    <IonLabel>Desktop</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="serveys">
                    <IonLabel>Serveys</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="my">
                    <IonLabel>My points</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <IonItem lines="none" className="sort-item">
                  <IonSelect
                    interface="popover"
                    value=""
                    onIonChange={e => console.log(e.detail.value)}
                    placeholder="Sort by"
                  >
                    <IonSelectOption value="first">One</IonSelectOption>
                    <IonSelectOption value="second">Two</IonSelectOption>
                    <IonSelectOption value="third">Three</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </div>
              <div className="cashier-free-apps-container">
                {cashierFreeApp.map((app, i) =>
                  <SingleCardFreeOffer key={i} app={app} i={i} setIdxFreeApp={setIdxFreeApp} />
                )}
              </div>
              <div className="service-container dark-blue-container flex">
                <div className="service-container-left flex">
                  <div className="service-container-label">Powered By</div>
                  <a href="/" className="service-container-offer-link">Offertoro</a>
                </div>
                <div className="service-container-right">
                  <Link to="/" className="modal-action">Terms of service</Link>
                </div>
              </div>
              <div className="modal-body-offer-footer flex">
                <div className="modal-body-offer-footer-info">
                  Lootably: 1 point = $1. Offertoro: 100 points = $1. Funds will be automatically transferred into your currently selected Roobet wallet once Offertoro/Lootably detects a completed offer. Completing surveys unfaithfully will result in a ban from surveys. All earnings from offers do not count as "real" deposits to unlock certain features on-site.
                </div>
                <div className="modal-body-item-footer-element">
                  <Link to="/" className="modal-action">Open in new window</Link>
                  <Link to="/" className="modal-action">Issues</Link>
                </div>
              </div>
            </div>
          }

          {cashierFreeTab === 'lootably' &&
            <div className="lootably-container">
              <div className="lootably-top flex">
                <div className="lootably-top-title-container flex">
                  <img src={lootablyIcon} alt="lootably icon" />
                  <div className="lootably-label">Lootably</div>
                </div>
                <div className="lootably-top-btns-container flex">
                  <div className="lootably-btn lootably-btn-sm">
                    <img src={settingsIcon} alt="settings icon" />
                  </div>
                  <div className="lootably-btn">FAQ</div>
                  <div className="lootably-btn">Support</div>
                </div>
              </div>
              <div className="cashier-free-sort-container flex">
                <IonSegment
                  scrollable
                  mode="ios"
                  value="all"
                  onIonChange={e => console.log('Segment selected', e.detail.value)}
                >
                  <IonSegmentButton value="all">
                    <IonLabel>All</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="serveys">
                    <IonLabel>Serveys</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="oneClick">
                    <IonLabel>One click</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="apps">
                    <IonLabel>Apps</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="videos">
                    <IonLabel>Videos</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="purchases">
                    <IonLabel>Purchases</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              <div className="cashier-free-apps-container">
                {cashierFreeApp.map((app, i) =>
                  <SingleCardFreeLootably key={i} app={app} i={i} setIdxFreeApp={setIdxFreeApp} />
                )}
              </div>
              <div className="service-container dark-blue-container flex">
                <div className="service-container-right lootably">
                  <Link to="/" className="modal-action">Privacy policy</Link>
                  <Link to="/" className="modal-action">Terms of service</Link>
                </div>
              </div>
              <div className="modal-body-offer-footer flex">
                <div className="modal-body-offer-footer-info">
                  Lootably: 1 point = $1. Offertoro: 100 points = $1. Funds will be automatically transferred into your currently selected Roobet wallet once Offertoro/Lootably detects a completed offer. Completing surveys unfaithfully will result in a ban from surveys. All earnings from offers do not count as "real" deposits to unlock certain features on-site.
                </div>
                <div className="modal-body-item-footer-element">
                  <Link to="/" className="modal-action">Open in new window</Link>
                  <Link to="/" className="modal-action">Issues</Link>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <div className={`modal-body-item ${(open.indexOf(1) !== -1 && 'open')}`}>
        <div
          className="modal-body-item-header"
          onClick={() => collapseDescr(1, open)}
        >
          <div className="modal-body-item-heading flex">
            <span>Promo</span>
            <span className="modal-body-item-header-text">Redeem a promotional code</span>
          </div>
          <div className="modal-body-item-arrow-container">
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="modal-body-item-collapse">
          <form className="modal-cashier-form">
            <div className="modal-cashier-form-inputs">
              <IonItem
                lines="none"
                className="ion-floating2-style"
              >
                <IonLabel position="floating">Enter a code</IonLabel>
                <IonInput
                  value=""
                  onIonChange={e => console.log(e.detail.value)}
                ></IonInput>
              </IonItem>
            </div>
          </form>
          <div className="modal-body-item-footer flex">
            <div className="modal-body-item-footer-element">
              <Link to="/" className="modal-action">Redeem Promotions</Link>
            </div>
          </div>
        </div>
      </div>

      <ModalCashierOfferApp app={cashierFreeApp[idxFreeApp]} />
      <ModalCashierLootablyApp app={cashierFreeApp[idxFreeApp]} />
    </div>
  )
}

export default TabFree;
