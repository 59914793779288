import React from 'react';
import { useAtom } from 'jotai';
import { tempNotificationData } from '../state';

const Notifications = () => {
  const [data,] = useAtom(tempNotificationData);

  return (
    <div className="header-notice-dropdown">
      <div className="header-notice-dropdown-title">Notifications</div>
      <div className="header-notice-dropdown-body">
        {data.map((item, i) =>
          <div className={`header-notice-dropdown-card ${item.isNew && 'new'}`} key={i}>
            <div className="header-notice-dropdown-card-top">
              <div className="header-notice-dropdown-time">{item.time}</div>
              <div className={`header-notice-dropdown-new ${item.isNew && 'new'}`}></div>
            </div>
            <div className="header-notice-dropdown-text">{item.text}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Notifications;
