import React from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';
import twitter from '../images/icons-social/twitter.svg';
import telegram from '../images/icons-social/telegram.svg';
import instagram from '../images/icons-social/instagram.svg'


const Footer = () => {


  return (
    <footer className="footer">
      <div className="footer-content flex column">
        <div className="footer-top">
          <div className="footer-top-left">
            <Link to="/home" className="footer-logo-container">
              <img src={logo} alt="logo" />
            </Link>
            <IonSelect
              className="select-lang select"
              interface="popover"
              value="en"
              onIonChange={e => console.log(e.detail.value)}
            >
              <IonSelectOption className="select-lang-option" value="en">English</IonSelectOption>
              <IonSelectOption className="select-lang-option" value="es">Spanish</IonSelectOption>
              <IonSelectOption className="select-lang-option" value="de">German</IonSelectOption>
            </IonSelect>
          </div>
          <div className="footer-vl not-mobile"></div>
          <div className="footer-top-center">
            <div className="footer-links">
              <Link to="/terms-of-service">ToS</Link>
              <Link to="/">AML</Link>
              <Link to="/">Fairness</Link>
              <Link to="/">Responsible gambling</Link>
              <Link to="/">VIP Club</Link>
            </div>
          </div>
          <div className="footer-vl"></div>
          <div className="footer-top-right">
            <div className="footer-top-right-content">
              <div className="footer-social-title">Social networks:</div>
              <div className="footer-socials">
                <Link to="/" className="footer-social-container">
                  <img src={twitter} alt="twitter" />
                </Link>
                <Link to="/" className="footer-social-container">
                  <img src={telegram} alt="telegram" />
                </Link>
                <Link to="/" className="footer-social-container">
                  <img src={instagram} alt="instagram" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          galaxybet.com is a brand name of TekHou5 Limited, Reg No 171902, having its registered address at 9 Barrack Road, Belize City, Belize, licensed to conduct online gaming operations by the Government of Curacao under license 365/JAZ, sub-license GLH-OCCHKTW0710102018. File reports here. Hou5Game Limited, Reg No. HE 391142, having its registered address at 1, Avlonos, Maria House, Nicosia, 1075 Cyprus, is a wholly owned subsidiary of TekHou5 Limited, which provides management, payment and support services related to the operation of the website. 18+ to play.
        </div>
      </div>
    </footer>
  )
}

export default Footer;
