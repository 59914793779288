import React, { useState } from 'react';
import { IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalCashierLootablyApp } from '../../state';
import closeModal from '../../images/icons-other/close-modal.svg';
import emailIcon from '../../images/icons-input/mail.svg';

const ModalCashierLootablyApp = (props) => {
  // console.log(props);
  const [isOpenModal, setIsOpenModal] = useAtom(modalCashierLootablyApp);

  const [cashierLootablyTab, setCashierLootablyTab] = useState('email');

  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal-smallest"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container-smallest">
        <div className="modal-smallest-header flex">
          <div className="modal-title">{props.app && props.app.title}</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
          />
        </div>
        <div className="modal-smallest-body custom-scroll">
          <IonSegment
            scrollable
            className="modal-tabs"
            mode="md"
            value={cashierLootablyTab}
            onIonChange={e => setCashierLootablyTab(e.detail.value)}
          >
            <IonSegmentButton value="email">
              <IonLabel>Email</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="qr">
              <IonLabel>QR code</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="url">
              <IonLabel>URL</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="segment-hl"></div>

          {cashierLootablyTab === 'email' &&
            <div className="modal-smallest-tab-content">
              <div className="modal-smallest-tab-content-main-text">Send this offer to you Email</div>
              <form
                className="modal-smallest-form"
                onSubmit={(e) => e.preventDefault()}
              >
                <IonItem
                  lines="none"
                  className="ion-floating2-style"
                >
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    value=""
                    onIonChange={e => console.log(e.detail.value)}
                  ></IonInput>
                  <IonIcon slot="end" icon={emailIcon}></IonIcon>
                </IonItem>
                <div className="modal-small-form-btn-container btn-container">
                  <IonButton
                    className="btn-orange"
                    type="submit"
                  >
                    Send link
                  </IonButton>
                </div>
                <IonItem lines="none" className="checkbox-item">
                  <IonCheckbox
                    checked="true"
                    onIonChange={e => {
                      // setChecked(e.detail.checked);
                      console.log(e.detail.checked)
                    }}
                  >
                  </IonCheckbox>
                  <IonLabel>
                    By providing your email address and checking this box, you agree that the info will be used by OfferToro in order to provide you an improved service
                  </IonLabel>
                </IonItem>
              </form>
            </div>
          }

          {cashierLootablyTab === 'qr' &&
            <div className="modal-smallest-tab-content">
              <div className="modal-smallest-tab-content-main-text">Scan QR code</div>
              <img className="qr" src={props.app.qr} alt="qr icon" />
            </div>
          }

          {cashierLootablyTab === 'url' &&
            <div className="modal-smallest-tab-content">
              <div className="modal-smallest-tab-content-main-text">Enter URL on your device</div>
              <div className="modal-smallest-tab-content-url-container">
                https://lootab.ly/f0ffd4g
              </div>
            </div>
          }
        </div>
      </div>
    </IonModal>
  )
}

export default ModalCashierLootablyApp;
