import React from 'react';
import { IonModal } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalError } from '../state';
import closeModal from '../images/icons-other/close-modal.svg';
import warningModal from '../images/icons-oval/warning.svg';


const ModalError = () => {
  const [isOpenModal, setIsOpenModal] = useAtom(modalError);

  return (
    <IonModal
      isOpen={isOpenModal}
      cssClass="modal-error"
      onDidDismiss={() => setIsOpenModal(false)}
    >
      <div className="modal-container-error">
        <div className="modal-error-header">
          <img
            className="modal-error-logo"
            src={warningModal}
            alt="app logo"
          />
          <div className="modal-title">Something went wrong!</div>
          <img
            className="modal-close-icon"
            src={closeModal}
            alt="close"
            onClick={() => setIsOpenModal(false)}
          />
        </div>
        <div className="modal-error-body custom-scroll">
          <div className="modal-error-info">There was an error processing the request.</div>
          <div className="modal-error-text">This offer is not available (#20005)</div>
        </div>
      </div>
    </IonModal>
  )
}

export default ModalError;
