import React from 'react';
import play from '../images/icons-other/play.svg';
import defaultImage from '../images/default-image.png';
import '../css/card-game.css';
import { useAtom } from 'jotai';
import { tokenValue } from '../state';


const CardGame = ({ game, showGame, showDemoGame }) => {
  const [token,] = useAtom(tokenValue);

  return (
    <div className="card-game">
      <div
        className="card-game-play-btn"
        onClick={() => token ? showGame(game.id) : showDemoGame(game.id)}
      >
        <img src={play} alt="play" />
      </div>
      <img className="card-game-img" src={game.banner_url || defaultImage} alt="game" />
      <div className="card-game-bottom">
        <div className="card-game-name">{game.name}</div>
        <div className="card-game-provider">{game.provider}</div>
      </div>
    </div>
  )
}

export default CardGame;
