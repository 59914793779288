import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  accountMobile, mobileMenuButton, modalAffiliate,
  modalCashier, modalGalaxyWards, modalHistory, modalPreference
} from '../state';
import cashierIcon from '../images/icons-header/sort-three-orange.svg';
import freePlayIcon from '../images/icons-header/database-point-orange.svg';
import accountIcon from '../images/icons-header/account-orange.svg';
import affiliateIcon from '../images/icons-header/chart-line-orange.svg';
import historyIcon from '../images/icons-header/history-orange.svg';
import promoIcon from '../images/icons-header/paper-money-orange.svg';
import galaxyWardsIcon from '../images/icons-header/gift-orange.svg';
import supportIcon from '../images/icons-header/headset-one-orange.svg';
import logoutIcon from '../images/icons-header/logout-orange.svg';

const AccountMobile = ({ logoutHandler }) => {
  const [isOpen, setIsOpen] = useAtom(accountMobile);
  const [menuBtn, setMenuBtn] = useAtom(mobileMenuButton);
  const [, setCashierOpen] = useAtom(modalCashier);
  const [, setHistoryOpen] = useAtom(modalHistory);
  const [, setAffiliateOpen] = useAtom(modalAffiliate);
  const [, setPreferenceOpen] = useAtom(modalPreference);
  const [, setGalaxyWardsOpen] = useAtom(modalGalaxyWards);

  useEffect(() => {
    const handleClickOut = (event) => {
      const modal = document.getElementById('account-window');
      const menuBottom = document.getElementById('mobile-menu-bottom');
      if (!event.path.includes(modal) && !event.path.includes(menuBottom)) {
        setIsOpen(false);
        setMenuBtn('home')
      };
    }

    if (menuBtn === 'account') {
      document.addEventListener('click', handleClickOut);
      return () => {
        document.removeEventListener('click', handleClickOut);
      };
    }
  }, [menuBtn, setMenuBtn, setIsOpen]);



  return (
    <div id={'account-window'} className={`account-mobile ${isOpen && 'open'}`}>
      <div className="account-mobile-header">Hey Yuliya!</div>
      <div className="account-mobile-body">
        <div
          className="account-mobile-item"
          onClick={() => { setIsOpen(false); setCashierOpen(true) }}
        >
          <div className="account-mobile-item-icon">
            <img src={cashierIcon} alt="cashier" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Cashier</div>
            <div className="account-mobile-item-info">Manage your wallets</div>
          </div>
        </div>
        <div className="account-mobile-item">
          <div className="account-mobile-item-icon">
            <img src={freePlayIcon} alt="free play" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Free Play</div>
            <div className="account-mobile-item-info">Add funds, play for free</div>
          </div>
        </div>
        <div
          className="account-mobile-item"
          onClick={() => { setIsOpen(false); setPreferenceOpen(true) }}
        >
          <div className="account-mobile-item-icon">
            <img src={accountIcon} alt="account" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Account</div>
            <div className="account-mobile-item-info">View your profile</div>
          </div>
        </div>
        <div
          className="account-mobile-item"
          onClick={() => { setIsOpen(false); setAffiliateOpen(true) }}
        >
          <div className="account-mobile-item-icon">
            <img src={affiliateIcon} alt="affiliate" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Affiliate</div>
            <div className="account-mobile-item-info">Open affiliate panel</div>
          </div>
        </div>
        <div
          className="account-mobile-item"
          onClick={() => { setIsOpen(false); setHistoryOpen(true) }}
        >
          <div className="account-mobile-item-icon">
            <img src={historyIcon} alt="history" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">History</div>
            <div className="account-mobile-item-info">View previous transactions</div>
          </div>
        </div>
        <div className="account-mobile-item">
          <div className="account-mobile-item-icon">
            <img src={promoIcon} alt="promo" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Promo</div>
            <div className="account-mobile-item-info">Redeem promotional offer</div>
          </div>
        </div>
        <div
          className="account-mobile-item"
          onClick={() => { setIsOpen(false); setGalaxyWardsOpen(true) }}
        >
          <div className="account-mobile-item-icon">
            <img src={galaxyWardsIcon} alt="galaxy wards" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">GalaxyWards</div>
            <div className="account-mobile-item-info">Claim loyalty bonus</div>
          </div>
        </div>
        <div className="account-mobile-item">
          <div className="account-mobile-item-icon">
            <img src={supportIcon} alt="live support" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Live Support</div>
            <div className="account-mobile-item-info">Speak to customer support</div>
          </div>
        </div>
        <div
          className="account-mobile-item"
          onClick={() => { logoutHandler(); setIsOpen(false) }}
        >
          <div className="account-mobile-item-icon">
            <img src={logoutIcon} alt="logout" />
          </div>
          <div className="account-mobile-item-text">
            <div className="account-mobile-item-name">Log Out</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountMobile;
