import React from 'react';
import { IonButton } from '@ionic/react';
import { useAtom } from 'jotai';
import { modalCashierLootablyApp } from '../../state';

const SingleCardFreeLootably = (props) => {
  const [, setCashierLootablyAppOpen] = useAtom(modalCashierLootablyApp);

  return (
    <div key={props.app.id} className="cashier-free-app-container flex">
      <div className="cashier-free-app-left flex">
        <div className="cashier-free-app-logo-container flex">
          <img src={props.app.logo} alt="app logo" className="cashier-free-app-logo" />
        </div>
        <div className="cashier-free-app-info">
          <div className="cashier-free-app-info-title">{props.app.title}</div>
          <div className="cashier-free-app-info-description">{props.app.description}</div>
          {props.app.devices && props.app.devices.map((el, i) =>
            <div key={i} className="cashier-free-app-device">{el}</div>
          )}
        </div>
      </div>
      <div className="form-cashier-btn-container btn-container">
        <IonButton
          className="btn-orange"
          onClick={() => {
            props.setIdxFreeApp(props.i);
            setCashierLootablyAppOpen(true)
          }}
        >
          +{props.app.lootablyPoints} points
        </IonButton>
      </div>
    </div>
  )
}

export default SingleCardFreeLootably;
