import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Link, useLocation } from 'react-router-dom';
import homeIcon from '../images/mobile-menu/home.svg';
import homeIconActive from '../images/mobile-menu/home-active.svg';
import notification from '../images/mobile-menu/notification.svg';
import notificationActive from '../images/mobile-menu/notification-active.svg';
import cashier from '../images/mobile-menu/cashier.svg';
import cashierActive from '../images/mobile-menu/cashier-active.svg';
import account from '../images/mobile-menu/account.svg';
import accountActive from '../images/mobile-menu/account-active.svg';
import { accountMobile, modalCashier, notificationsMobile, mobileMenuButton } from '../state.js';
const MobileMenu = () => {
  let location = useLocation();
  const [cashierOpen, setCashierOpen] = useAtom(modalCashier);
  const [, setNotificationsOpen] = useAtom(notificationsMobile);
  const [, setAccountOpen] = useAtom(accountMobile);
  const [btn, setBtn] = useAtom(mobileMenuButton);


  // useEffect(()=> {
  //   if (cashierOpen === false && location.pathname === '/home') {
  //     setBtn('home');
  //   }
  // },[cashierOpen]);


  useEffect(() => {
    if (btn !== 'account') {
      setAccountOpen(false);
    }
    if (btn !== 'notifications') {
      setNotificationsOpen(false);
    }
  }, [btn, setAccountOpen, setNotificationsOpen]);



  return (
    <div id="mobile-menu-bottom" className="mobile-menu-bottom">
      <Link onClick={() => setBtn('home')} to="/home" className={"mobile-menu-btn " + (location.pathname === '/home' && btn === 'home' ? 'mobile-menu-active' : '')}>
        <div className='mobile-menu-icon-container'>
          {location.pathname !== '/home' && <img src={homeIcon} alt='home' className="link-icon"></img>}
          {location.pathname === '/home' && btn !== 'home' && <img src={homeIcon} alt='home' className="link-icon"></img>}
          {location.pathname === '/home' && btn === 'home' && <img src={homeIconActive} alt='home' className="link-icon"></img>}
        </div>
        <p className="link-text">Home</p>
      </Link>
      <div onClick={() => { setBtn('cashier'); setCashierOpen(true) }} className={"mobile-menu-btn " + (btn === 'cashier' && cashierOpen === true ? 'mobile-menu-active' : '')}>
        <div className='mobile-menu-icon-container'>
          {btn !== 'cashier' && <img src={cashier} alt='home' className="link-icon"></img>}
          {btn === 'cashier' && cashierOpen === true && <img src={cashierActive} alt='home' className="link-icon"></img>}
        </div>
        <p className="link-text">Cashier</p>
      </div>
      <div onClick={() => { setBtn('notifications'); setNotificationsOpen(true) }} className={"mobile-menu-btn " + (btn === 'notifications' ? 'mobile-menu-active' : '')}>
        <div className='mobile-menu-icon-container'>
          {btn !== 'notifications' && <img src={notification} alt='home' className="link-icon"></img>}
          {btn === 'notifications' && <img src={notificationActive} alt='home' className="link-icon"></img>}
        </div>
        <p className="link-text">Notifications</p>
      </div>
      <div onClick={() => { setBtn('account'); setAccountOpen(true) }} className={"mobile-menu-btn " + (btn === 'account' ? 'mobile-menu-active' : '')}>
        <div className='mobile-menu-icon-container'>
          {btn !== 'account' && <img src={account} alt='home' className="link-icon"></img>}
          {btn === 'account' && <img src={accountActive} alt='home' className="link-icon"></img>}
        </div>
        <p className="link-text">Account</p>
      </div>
    </div>
  )
}

export default MobileMenu
