import axios from 'axios';
import url from '../axios.js';
import { getFP } from '../utils/functions';
// import FP from '@fingerprintjs/fingerprintjs-pro';
import { v4 as uuidv4 } from 'uuid';


axios.interceptors.response.use(response => {
  if (response.data.message === "Unauthenticated.") {
    console.log("Unauthenticated.");
    localStorage.removeItem("token");
    localStorage.clear();
    // window.location.href="/login";
  }
  return response;
}, function (error, res) {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.clear();
    // window.location.href="/login";
  }
  return Promise.reject(error);
});


// Login
export async function login(username, password) {
  let response;
  const uuid = uuidv4();
  response = await axios({
    method: 'post',
    url: url + "/api/login",
    data: {
      uuid: uuid,
      login: username,
      password: password
    }
  });
  getFP();
  return response;
};


// Logout
export async function logout(token) {
  let response;
  response = await axios({
    method: 'post',
    url: url + '/api/logout',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  return response;
};

// Forgot Password
export async function forgot(user) {
  let response;
  response = await axios({
    method: 'post',
    url: url + "/api/restore-password",
    data: {
      restore: user
    }
  })
  return response;
}


// Forgot Password Verification
export async function forgotVerification(user, vcode, pass) {
  let response;
  response = await axios({
    method: 'post',
    url: url + "/api/restore-confirm",
    data: {
      password: pass,
      confirmation: vcode,
      restore: user
    }
  })
  return response;
}


// Check Username
export async function checkusername(username) {
  let response;
  response = await axios({
    method: 'post',
    url: url + '/api/checkusername',
    data: {
      login: username
    }
  })
  return response;
}


// Check Email
export async function checkemail(email) {
  let response;
  response = await axios({
    method: 'post',
    url: url + '/api/checkemail',
    data: {
      email: email
    }
  })
  return response;
}


// get Countries
export async function getCountries() {
  let response;
  response = await axios({
    method: 'post',
    url: url + '/api/permitted-countries',
  })
  return response;
}


// get Currencies
export async function getCurrencies() {
  let response;
  response = await axios({
    method: 'post',
    url: url + '/api/get-currencies'
  })
  return response;
}


// register
export async function register(data) {
  let response;
  response = axios({
    method: 'post',
    url: url + '/api/register',
    data: data
  })
  return response;
}


// get user info
export async function getInfo(token) {
  let response;
  response = await axios({
    method: 'post',
    url: url + '/api/user',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });
  return response.data.data;
}


// get games 
export async function getGames(token) {
  let response;
  if (token) {
    // Get list of auth games 
    response = await axios({
      method: 'post',
      url: url + '/api/list-games-auth',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      data: {
        platform: window.innerWidth <= 800 ? 'mobile' : 'desktop',
      }
    })
    return response.data.data;
  } else {
    // Get list of new games 
    response = await axios({
      method: 'post',
      url: url + '/api/list-games-new',
      data: {
        platform: window.innerWidth <= 800 ? 'mobile' : 'desktop',
      }
    });
    return response.data.data;
  }
}


//get tags
export async function getTags() {
  let response;
  response = await axios(
    {
      method: 'post',
      url: url + '/api/list-tags'
    });
  return response.data.data;
}


// run game
export async function runGame(token, id, lang, currency) {
  let response;
  response = await axios({
    method: "post",
    url: url + "/api/run-game",
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      id: id,
      lang: lang,
      currency: currency
    }
  });
  return response.data.data;
}


// run game's demo
export async function runGameDemo(token, id, lang, result) {
  let response;
  response = await axios({
    method: "post",
    url: url + "/api/run-demo-game",
    headers: {
      Authorization: token ? `Bearer ${token}` : JSON.stringify(result)
    },
    data: {
      id: id,
      lang: lang
    }
  });
  return response.data.data;
}