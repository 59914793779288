import React from 'react';
import SingleCardDeposit from './SingleCardDeposit';
import exchangeIcon from '../../images/icons-oval/exchange.svg';
import buyIcon from '../../images/icons-oval/buy.svg';
import bitcoinIcon from '../../images/icons-oval/bitcoin-oval.svg';
import ethereumIcon from '../../images/icons-oval/ethereum-oval.svg';

const TabDeposit = () => {
  return (
    <div className="modal-content-body">
      <div className="modal-body-item">
        <div className="modal-body-item-title">Need to buy Bitcoin or Ethereum?</div>
        <div className="modal-body-item-description">
          <img className="icon" src={exchangeIcon} alt="exchange icon" />
          <div className="modal-body-item-description-right">
            <div className="modal-body-item-heading">Choose an Exchange</div>
            <div className="modal-body-item-description-text">
              There are many recommended exchanges/apps which allow instant purchase of BTC/ETH with credit cardsor bank account. We recommend Exodus (iPhone Only), Coinbase, Cash App or Blockchain.
            </div>
          </div>
        </div>
        <div className="modal-body-item-description">
          <img className="icon" src={buyIcon} alt="buy icon" />
          <div className="modal-body-item-description-right">
            <div className="modal-body-item-heading">Buy & Send</div>
            <div className="modal-body-item-description-text">
              Once you've signed up you can now link credit cards or bank accounts to purchase BTC/ETH. Once you make a purchase, you are then able to send BTC/ETH to your corresponding addresses below to deposit on Gotowin.
            </div>
          </div>
        </div>
      </div>

      {[{ currency: 'Bitcoin', icon: bitcoinIcon }, { currency: 'Ethereum', icon: ethereumIcon }]
        .map((item, i) => <SingleCardDeposit item={item} i={i} key={i} />)
      }
    </div>
  )
}

export default TabDeposit;
