import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useState } from 'react';
import dollarSmallIcon from '../../images/icons-input/dollar-small.svg';
import arrow from '../../images/icons-input/select-dropdown.svg';

const SingleCardWithdraw = (props) => {
  const [open, setOpen] = useState([]);
  const collapseDescr = (e, arr) => {
    let tempArr = arr;
    if (!tempArr.includes(e)) {
      setOpen([...arr, e]);
    } else {
      tempArr.splice(tempArr.indexOf(e), 1);
      setOpen([...tempArr]);
    };
  }

  return (
    <div className={`modal-body-item ${(open.indexOf(props.i) !== -1 && 'open')}`} key={props.i}>
      <div
        className="modal-body-item-header"
        onClick={() => collapseDescr(props.i, open)}
      >
        <img className="icon" src={props.item.icon} alt={`${props.item.currency} icon`} />
        <div className="modal-body-item-heading">{props.item.currency}</div>
        <div className="modal-body-item-currancy">$0.00</div>
        <div className="modal-body-item-arrow-container">
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <div className="modal-body-item-collapse">
        <form className="modal-cashier-form" onSubmit={(e) => e.preventDefault()}>
          <div className="modal-cashier-form-inputs modal-cashier-form-notification">
            <IonItem
              lines="none"
              className="ion-floating2-style"
            >
              <IonLabel position="floating">{props.item.currency} Address</IonLabel>
              <IonInput
                value=""
                onIonChange={e => console.log(e.detail.value)}
              ></IonInput>
              <IonIcon slot="end" icon={props.item.smallIcon}></IonIcon>
            </IonItem>
            <div className="input-container">
              <IonItem
                lines="none"
                className="ion-floating2-style 
              with-notification"
              >
                <IonLabel position="floating">Amount</IonLabel>
                <IonInput
                  type="number"
                  value=""
                  onIonChange={e => console.log(e.detail.value)}
                ></IonInput>
                <IonIcon
                  slot="end"
                  icon={dollarSmallIcon}
                ></IonIcon>
              </IonItem>
              <div className="input-notification">Minimum withdrawal amount is $10.00</div>
            </div>
            <IonItem
              lines="none"
              className="ion-floating2-style"
            >
              <IonSelect
                interface="popover"
                value=""
                onIonChange={e => console.log(e.detail.value)}
                placeholder="Select Priority"
              >
                <IonSelectOption value="first">1</IonSelectOption>
                <IonSelectOption value="second">2</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
          <div className="modal-cashier-form-bottom">
            <div className="withdraw-form-bottom-left">
              <div className="withdraw-form-bottom-left-currancy">$1.75</div>
              <div className="withdraw-form-bottom-left-text">Transaction Fee</div>
            </div>
            <div className="form-cashier-btn-container btn-container">
              <IonButton
                className="btn-orange"
                type="submit"
              >
                Withdraw funds
              </IonButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SingleCardWithdraw;
