import React from 'react';
import { IonLoading } from '@ionic/react';
import { useAtom } from "jotai";
import { loadingMain } from "../state.js";
import '../css/loading.css';

const LoadingMain = () => {
  const [isOpen, setIsOpen] = useAtom(loadingMain);



  return (
    <IonLoading
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      spinner={'dots'}
      duration={5000}
      cssClass={'loading-hidden'}
    />
  )
}

export default LoadingMain;