import FP from '@fingerprintjs/fingerprintjs-pro';
import { v4 as uuidv4 } from 'uuid';


export const copyText = (text) => {
  navigator.clipboard.writeText(text);
  navigator.clipboard.readText()
    .then(text => {
      console.log(text);
    })
    .catch(err => {
      console.log('Something went wrong', err);
    });
}

// Finger Print
export const getFP = async () => {
  const uuid = uuidv4();
  const requestMetadata = { UUID: uuid };
  const fp = await FP.load({ token: "UCmjTCW278UaQTDLjsMJ", region: "eu" });
  const res = await fp.get({ tag: requestMetadata, callbackData: true });
  return res;
}